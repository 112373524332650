import graphql from "babel-plugin-relay/macro";

export const RemoveRepositoryMutation = graphql`
  mutation RemoveRepositoryMutation($url: String!) {
    removeRepository(url: $url) {
      repository {
        id @deleteRecord
      }
    }
  }
`;
