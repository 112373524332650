import RectangleSkeleton from "./RectangleSkeleton";
import TableSkeleton from "./TableSkeleton";

interface Props {
  searchBar?: boolean;
}

export default function DetailsSkeleton({ searchBar }: Props) {
  return (
    <>
      <RectangleSkeleton height="32px" topMargin={13} />
      <RectangleSkeleton height="225px" topMargin={5} />
      {searchBar ? <RectangleSkeleton height="32px" topMargin={7} /> : null}
      <TableSkeleton topMargin={5} />
    </>
  );
}
