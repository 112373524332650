import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import * as Sentry from "@sentry/browser";
import { CATEGORICAL_CHART_COLORS } from "@stacklet/ui";

import toKebabCase from "app/utils/toKebabCase";
import {
  Bar as BarChart,
  ChartErrorBoundary,
  ValueTooltip,
} from "app/views/dashboards/components";
import { truncateAxis } from "app/views/dashboards/utils";

import type { Query } from "@cubejs-client/core";

interface Props {
  query: Query;
  title: string;
  subtitle: string;
}

export default function CubeBarChart({
  query,
  title,
  subtitle,
  ...rest
}: Props) {
  const { resultSet, isLoading, error } = useCubeQuery(query);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        height={300}
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
    throw error;
  }

  if (!resultSet) {
    return null;
  }

  const keys = resultSet.seriesNames().map((item) => item.key);

  const data = resultSet.chartPivot();

  const legendData = keys.map((id, index) => ({
    id,
    label: "values",
    color: CATEGORICAL_CHART_COLORS[index],
  }));

  return (
    <ChartErrorBoundary name={title.toLocaleLowerCase()}>
      <BarChart
        barColors={CATEGORICAL_CHART_COLORS}
        data={data}
        keys={keys}
        legendData={legendData}
        subtitle={subtitle}
        title={title}
        tooltip={ValueTooltip}
        wrapperTestID={`${toKebabCase(title)}-chart`}
        xAxisFormat={truncateAxis}
        {...rest}
      />
    </ChartErrorBoundary>
  );
}
