import graphql from "babel-plugin-relay/macro";

export const RemoveAccountGroupMappingsMutation = graphql`
  mutation RemoveAccountGroupMappingsMutation(
    $input: RemoveAccountGroupMappingsInput!
  ) {
    removeAccountGroupMappings(input: $input) {
      removed {
        id @deleteRecord
      }
    }
  }
`;
