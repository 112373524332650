import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";
import { nanoid } from "nanoid";

import { ReactComponent as defaultLogo } from "assets/icons/fi_layers.svg";
import { ReactComponent as adminIcon } from "assets/icons/sidebar/admin.svg";
import { ReactComponent as notificationsIcon } from "assets/icons/sidebar/bell.svg";
import { ReactComponent as cloudOperationsIcon } from "assets/icons/sidebar/cloud-operations.svg";
import { ReactComponent as homeIcon } from "assets/icons/sidebar/home.svg";
import { ReactComponent as settingsIcon } from "assets/icons/sidebar/settings.svg";
import { ReactComponent as toolsIcon } from "assets/icons/sidebar/tools.svg";

interface Props {
  title: string;
}

export default function SidebarIcon({ title }: Props): JSX.Element {
  let SidebarLogo;

  switch (title) {
    case "overview":
      SidebarLogo = homeIcon;
      break;
    case "cloudops":
      SidebarLogo = cloudOperationsIcon;
      break;
    case "admin":
      SidebarLogo = adminIcon;
      break;
    case "notifications":
      SidebarLogo = notificationsIcon;
      break;
    case "stacklet-tools":
      SidebarLogo = toolsIcon;
      break;
    case "settings":
      SidebarLogo = settingsIcon;
      break;

    default:
      SidebarLogo = defaultLogo;
  }

  const id = `logo-tooltip-${nanoid()}`;

  return (
    <Box
      sx={{
        display: "flex",
        height: "16px",
        width: "16px",
      }}
    >
      <Tooltip id={id} title={title}>
        <SidebarLogo aria-labelledby={id} />
      </Tooltip>
    </Box>
  );
}
