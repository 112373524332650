import graphql from "babel-plugin-relay/macro";

export const AddRepositoryMutation = graphql`
  mutation AddRepositoryMutation(
    $connections: [ID!]!
    $input: RepositoryInput!
  ) {
    addRepository(input: $input) {
      repository
        @appendNode(connections: $connections, edgeTypeName: "RepositoryEdge") {
        id
        name
        url
        head
        authUser
        hasAuthToken
        hasSshPassphrase
        hasSshPrivateKey
        lastScanned
        description
        error
        branchName
        policyDirectories
        policyFileSuffix
      }
    }
  }
`;
