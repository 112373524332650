import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import * as Sentry from "@sentry/browser";

import { dataFromResultSet } from "app/views/dashboards/utils";

import ValueChart from "./ValueChart";

import type { Props as ValueChartProps } from "./ValueChart";
import type { Query } from "@cubejs-client/core";

type ValueData = {
  key: string;
  value: number;
}[];

interface Props extends Omit<ValueChartProps, "data"> {
  query: Query;
  processResult?(data: ValueData): ValueData | null;
}

export default function CubeValueChart({
  query,
  processResult,
  ...rest
}: Props): JSX.Element | null {
  const { resultSet, isLoading, error } = useCubeQuery(query);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        height={45}
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
    throw error;
  }

  if (!resultSet) {
    return null;
  }

  const data = dataFromResultSet(resultSet);

  if (!data.length) {
    return null;
  }

  let processedData;
  if (processResult) {
    processedData = processResult(data);
    if (!processedData) {
      return null;
    }
  }

  return <ValueChart data={processedData || data} {...rest} />;
}
