import { forwardRef } from "react";

import { Tooltip as MuiTooltip } from "@mui/material";

import { COLORS } from "../../theme";

import type { TooltipProps } from "@mui/material";

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ ...props }, ref) => {
    return (
      <MuiTooltip
        {...props}
        ref={ref}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: COLORS.navy.L10,
              borderRadius: "0.25rem",
            },
          },
        }}
      >
        {props.children}
      </MuiTooltip>
    );
  }
);
