import graphql from "babel-plugin-relay/macro";

export const AddBindingMutation = graphql`
  mutation AddBindingMutation($connections: [ID!]!, $input: AddBindingInput!) {
    addBinding(input: $input) {
      binding
        @appendNode(connections: $connections, edgeTypeName: "BindingEdge") {
        id
        uuid
        name
        description
        autoDeploy
        lastDeployed
        accountGroup {
          name
          uuid
          provider
        }
        policyCollection {
          name
          uuid
          provider
        }
        schedule
        executionConfig {
          dryRun {
            default
          }
          variables
        }
      }
    }
  }
`;
