import graphql from "babel-plugin-relay/macro";

export const AddTemplateMutation = graphql`
  mutation AddTemplateMutation($input: TemplateInput!, $connections: [ID!]!) {
    addTemplate(input: $input) {
      template
        @appendNode(connections: $connections, edgeTypeName: "TemplateEdge") {
        name
        content
        transport
      }
    }
  }
`;
