import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ResponsivePie } from "@nivo/pie";
import { CATEGORICAL_CHART_COLORS } from "@stacklet/ui";

export interface Props {
  title: string;
  data: {
    id: string | number | boolean;
    value: string | number | boolean;
    label?: string | number;
  }[];
  wrapperTestId?: string;
  barColors?: string[];
}

export default function Pie({
  title,
  data,
  wrapperTestId = "",
  barColors = CATEGORICAL_CHART_COLORS,
}: Props): JSX.Element | null {
  return (
    <Box data-testid={wrapperTestId}>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box sx={{ height: 300 }}>
        <ResponsivePie
          activeOuterRadiusOffset={0}
          arcLabelsRadiusOffset={0}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          arcLinkLabelsColor={{ from: "color" }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsThickness={2}
          borderColor={{
            from: "color",
          }}
          colors={barColors}
          data={data}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          innerRadius={0.6}
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 60,
              translateY: 30,
              itemsSpacing: 6,
              itemWidth: 100,
              itemHeight: 18,
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "black",
                  },
                },
              ],
            },
          ]}
          margin={{ top: 20, right: 100, bottom: 20 }}
        />
      </Box>
    </Box>
  );
}
