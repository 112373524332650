import { useCubeQuery } from "@cubejs-client/react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { Compliance } from "../cards/compliance/Compliance";
import { AnnualCost } from "../cards/cost/AnnualCost";
import { Findings } from "../cards/findings/Findings";

import type { SectionModalResources_dashboard$key } from "./__generated__/SectionModalResources_dashboard.graphql";

interface Props {
  dashboard: SectionModalResources_dashboard$key;
}

export function SectionModalResources({ dashboard }: Props) {
  const data = useFragment(
    graphql`
      fragment SectionModalResources_dashboard on Dashboard {
        key
        metric
        section(sectionKey: $sectionKey) {
          key
        }
        title
      }
    `,
    dashboard,
  );

  // We use "Yesterday" here b/c we might not have ControlSignal data for today yet
  const mostRecentDate = "Yesterday";
  const query = useCubeQuery([
    {
      measures: ["ControlSignal.distinctResources"],
      timeDimensions: [
        {
          dimension: "ControlSignal.date",
          granularity: "day",
          dateRange: mostRecentDate,
        },
      ],
      filters: [
        {
          member: "Dashboard.key",
          operator: "equals",
          values: [data.key],
        },
        {
          member: "DashboardSection.key",
          operator: "equals",
          values: [data.section?.key || ""],
        },
      ],
    },
    {
      measures: ["ResourceCount.resourceCount"],
      filters: [
        {
          member: "Dashboard.key",
          operator: "equals",
          values: [data.key],
        },
        {
          member: "DashboardSection.key",
          operator: "equals",
          values: [data.section?.key || ""],
        },
      ],
      timeDimensions: [
        {
          dimension: "ResourceCount.date",
          granularity: "day",
          dateRange: mostRecentDate,
        },
      ],
    },
  ]);

  const results = query?.resultSet?.tablePivot()[0] ?? {};
  const findingCount = results["ControlSignal.distinctResources"] as number;
  const resourceCount = Math.round(
    Number(results["ResourceCount.resourceCount"]),
  );

  const isCostDashboard = data.key.includes("cost");

  return (
    <>
      <Divider />
      <Box>
        <Typography sx={{ mb: 1 }} variant="h4">
          Resources
        </Typography>

        {resourceCount && findingCount ? (
          <Typography sx={{ mb: 1 }} variant="body1">
            {`For this recommendation, we evaluated ${resourceCount} resources with ${findingCount} findings.`}
          </Typography>
        ) : null}

        <Grid direction="row" justifyContent="flex-start" spacing={2} container>
          {!isCostDashboard ? (
            <Grid xs={6} item>
              <Compliance
                dashboardKey={data.key}
                metric={data.metric}
                sectionKey={data.section?.key}
                title={data.title}
              />
            </Grid>
          ) : null}

          <Grid xs={6} item>
            <Findings
              dashboardKey={data.key}
              sectionKey={data.section?.key}
              title={data.title}
              enableMenu
            />
          </Grid>

          {isCostDashboard ? (
            <Grid xs={6} item>
              <AnnualCost
                dashboardKey={data.key}
                sectionKey={data.section?.key}
                compact
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </>
  );
}
