// DEFAULT_PAGE_SIZE must be large enough to prevent fetching next page on first page load

import type { Permission } from "app/contexts/UserContext";

// for store and network queries to prevent race condition with relay
export const DEFAULT_PAGE_SIZE = 100;

export const adminPermissions: Permission = {
  object: "system",
  action: "write",
};

export const NON_SSO_ROLE = "admin";

export const EDIT_FORM_SUBMIT_BUTTON_NAME = "Save changes";
export const EDIT_FORM_LOADING_SUBMIT_BUTTON_NAME = "Saving changes";
