import React from "react";

import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TooltipWrapper } from "@nivo/tooltip";
import { TOOLTIP_BG_DARK } from "@stacklet/ui";

import type { ChoroplethBoundFeature } from "@nivo/geo";

interface Props {
  feature: ChoroplethBoundFeature;
  wrapperTestId?: string;
  children: React.ReactNode;
}

export default function Tooltip({
  feature,
  children,
}: Props): JSX.Element | null {
  const smallScreen = useMediaQuery("(max-width:1500px)");

  if (!feature?.data) return null;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<Props>, {
        feature,
      });
    }
    return child;
  });

  return (
    <TooltipWrapper anchor="top" position={[0, 0]}>
      <Box
        sx={{
          padding: 1,
          background: TOOLTIP_BG_DARK,
          color: "white",
          minWidth: smallScreen ? "140px" : "170px",
          fontSize: (theme) =>
            smallScreen
              ? theme.typography.pxToRem(8)
              : theme.typography.pxToRem(10),
        }}
      >
        {childrenWithProps}
      </Box>
    </TooltipWrapper>
  );
}
