import { useSearchParams } from "react-router-dom";

export type GenericFilter = {
  value: string;
};

export type TagFilter = {
  key: string;
  operator: string;
  tagValue: string;
};

export type Filter = TagFilter[] | GenericFilter[] | string[];

export type Filters = { [x: string]: Filter };

/** Clean form filter values, removing keys with empty array values */
function cleanFormFilters(values: Filters) {
  const cleanValues: Filters = {};
  Object.keys(values).forEach((key) => {
    const property = values[key];
    if (!property?.length) {
      return;
    }
    cleanValues[key] = property;
  });
  return cleanValues;
}

// returns formatted filters from url search params
export function useFilterParams(): [
  Filters | null,
  (filters: Filters) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams({});
  const search = searchParams.get("filters");
  const filters: Filters = search ? JSON.parse(search) : null;

  function setFilterParams(filters: Filters) {
    const cleanedFilters = cleanFormFilters(filters);

    if (Object.keys(cleanedFilters).length === 0) {
      searchParams.set("filters", "");
    } else {
      searchParams.set("filters", JSON.stringify(cleanedFilters));
    }
    setSearchParams(searchParams, { replace: true });
  }

  return [filters, setFilterParams];
}
