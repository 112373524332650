/**
 * @generated SignedSource<<986690da5dd49529c90663ae5721e5ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CloudProvider = "AWS" | "Azure" | "GCP" | "Kubernetes" | "TencentCloud" | "%future added value";
export type AccountGroupItemsInput = {
  items: ReadonlyArray<AccountGroupElement>;
  uuid: string;
};
export type AccountGroupElement = {
  key: string;
  provider: CloudProvider;
  regions?: ReadonlyArray<string> | null | undefined;
};
export type AddAccountGroupItemsMutation$variables = {
  input: AccountGroupItemsInput;
};
export type AddAccountGroupItemsMutation$data = {
  readonly addAccountGroupItems: {
    readonly group: {
      readonly description: string | null | undefined;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly key: string;
        readonly name: string | null | undefined;
        readonly provider: CloudProvider;
        readonly regions: ReadonlyArray<string> | null | undefined;
        readonly uuid: string;
      }> | null | undefined;
      readonly name: string;
      readonly provider: CloudProvider;
      readonly regions: ReadonlyArray<string> | null | undefined;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddAccountGroupItemsMutation = {
  response: AddAccountGroupItemsMutation$data;
  variables: AddAccountGroupItemsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountGroupPayload",
    "kind": "LinkedField",
    "name": "addAccountGroupItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountGroup",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountGroupItem",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v4/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAccountGroupItemsMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAccountGroupItemsMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "eda40b4cc169f07706f5604768f91799",
    "id": null,
    "metadata": {},
    "name": "AddAccountGroupItemsMutation",
    "operationKind": "mutation",
    "text": "mutation AddAccountGroupItemsMutation(\n  $input: AccountGroupItemsInput!\n) {\n  addAccountGroupItems(input: $input) {\n    group {\n      id\n      uuid\n      name\n      description\n      regions\n      provider\n      items {\n        uuid\n        key\n        provider\n        name\n        regions\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8a3cb29d5a375b107e76abf51cf7fa2";

export default node;
