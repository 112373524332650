import graphql from "babel-plugin-relay/macro";

export const RemovePolicyCollectionMappingsMutation = graphql`
  mutation RemovePolicyCollectionMappingsMutation(
    $input: RemovePolicyCollectionMappingsInput!
  ) {
    removePolicyCollectionMappings(input: $input) {
      removed {
        id @deleteRecord
      }
    }
  }
`;
