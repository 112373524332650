import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { Tooltip } from "@stacklet/ui";
import { COLORS } from "@stacklet/ui";

interface Props {
  status: "success" | "error";
  message?: string;
  inline?: boolean;
}

export default function StatusIcon({
  status,
  message,
  inline = false,
}: Props): JSX.Element {
  return (
    <>
      {status === "error" ? (
        <Tooltip title={message || ""}>
          <ErrorIcon
            aria-label="error"
            data-testid="error-icon"
            fontSize={inline ? "inherit" : "medium"}
            sx={{ mr: inline ? 0.5 : undefined, fill: COLORS.red.D20 }}
          />
        </Tooltip>
      ) : null}
      {status === "success" ? (
        <CheckCircleIcon aria-label="success" sx={{ fill: "green" }} />
      ) : null}
    </>
  );
}
