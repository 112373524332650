import { Tooltip } from "@stacklet/ui";
import { formatDistance } from "date-fns";

interface Props {
  timestamp?: string | null;
}

export function HumanTimestamp({ timestamp }: Props) {
  if (!timestamp) {
    return <span>--</span>;
  }

  return (
    <Tooltip title={timestamp}>
      <span>
        {formatDistance(new Date(timestamp), new Date(), {
          addSuffix: true,
        })}
      </span>
    </Tooltip>
  );
}
