/**
 * @generated SignedSource<<7acb011e26c3531ae2c76e011d620bbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RoutesAccountsQuery$variables = Record<PropertyKey, never>;
export type RoutesAccountsQuery$data = {
  readonly accounts: {
    readonly pageInfo: {
      readonly total: number | null | undefined;
    };
  };
  readonly whoami: {
    readonly __typename: "CurrentUser";
    readonly config: {
      readonly isOnboardingSkipped: boolean | null | undefined;
    } | null | undefined;
  };
};
export type RoutesAccountsQuery = {
  response: RoutesAccountsQuery$data;
  variables: RoutesAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountConnection",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrentUser",
    "kind": "LinkedField",
    "name": "whoami",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Config",
            "kind": "LinkedField",
            "name": "config",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOnboardingSkipped",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutesAccountsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoutesAccountsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5e9732bc206ef51138af697a2e6a9c4e",
    "id": null,
    "metadata": {},
    "name": "RoutesAccountsQuery",
    "operationKind": "query",
    "text": "query RoutesAccountsQuery {\n  accounts {\n    pageInfo {\n      total\n    }\n  }\n  whoami {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "14c3850a8889dc5d19418b39d9ff86b4";

export default node;
