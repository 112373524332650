import graphql from "babel-plugin-relay/macro";
import { lazily } from "react-lazily";
import { useLazyLoadQuery } from "react-relay";
import { Routes, Navigate, Route } from "react-router-dom";

import { useConfig } from "app/contexts";
import { useHasRequiredPermission } from "app/hooks";
import { adminPermissions } from "app/utils/consts";
import { Activity } from "app/views/overview/activity/Activity";

import type { RoutesAccountsQuery } from "__generated__/RoutesAccountsQuery.graphql";

const {
  AccountGroups,
  Admin,
  Bindings,
  CloudOps,
  Dashboards,
  NotFound,
  Notifications,
  Onboarding,
  Policies,
  PolicyCollections,
  Accounts,
} = lazily(() => import("app/views"));

const AppRoutes = (): JSX.Element => {
  const config = useConfig();
  const hasPermission = useHasRequiredPermission();

  const data = useLazyLoadQuery<RoutesAccountsQuery>(
    graphql`
      query RoutesAccountsQuery {
        accounts {
          pageInfo {
            total
          }
        }
        whoami {
          __typename
          config {
            isOnboardingSkipped
          }
        }
      }
    `,
    {},
  );

  if (
    data.accounts.pageInfo.total === 0 &&
    !data.whoami.config?.isOnboardingSkipped
  ) {
    return (
      <Routes>
        <Route element={<Navigate to="/onboarding" />} path="/*" />
        <Route element={<Onboarding />} path="onboarding/*" />
      </Routes>
    );
  }

  const overviewRerouteTarget = config?.dashboards.includes("cubejs")
    ? "/dashboards/cloud"
    : "/policies";

  return (
    <Routes>
      <Route element={<Onboarding />} path="onboarding/*" />
      <Route element={<Navigate to="/overview" />} index />
      <Route element={<Navigate to="/overview" />} path="setup/*" />
      <Route
        element={<Navigate to={overviewRerouteTarget} />}
        path="overview/*"
      />
      <Route element={<Activity />} path="activity/*" />
      <Route element={<Dashboards />} path="dashboards/*" />
      <Route element={<Policies />} path="policies/*" />
      <Route element={<CloudOps />} path="cloudops/*" />
      <Route
        element={hasPermission(adminPermissions) ? <Admin /> : <NotFound />}
        path="admin/*"
      />
      <Route element={<Accounts />} path="accounts/*" />
      <Route element={<Bindings />} path="bindings/*" />
      <Route element={<PolicyCollections />} path="policy-collections/*" />
      <Route element={<AccountGroups />} path="account-groups/*" />
      <Route
        element={
          hasPermission(adminPermissions) ? <Notifications /> : <NotFound />
        }
        path="notifications/*"
      />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default AppRoutes;
