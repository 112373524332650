import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import { useConfig } from "app/contexts";

export function useSentry(version: string) {
  const config = useConfig();

  useEffect(() => {
    if (config?.sentry_enabled && version) {
      Sentry.init({
        autoSessionTracking: true,
        dsn: "https://71639aea5fcd412ab46d72c67fa23660@o553835.ingest.sentry.io/5697728",
        environment: process.env.NODE_ENV || "unknown",
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
        ],
        release: version ? `platform@${version}` : undefined,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // In client production builds, this should be set to 0.01
        tracesSampler: () => {
          if (process.env.REACT_APP_SENTRY_TRACE_RATE) {
            try {
              return parseFloat(process.env.REACT_APP_SENTRY_TRACE_RATE);
            } catch {
              console.warn(
                "Invalid value set for REACT_APP_SENTRY_TRACE_RATE, defaulting to 0.01",
              );
            }
          }
          return 0.01;
        },
      });
      Sentry.setTag("ui.git_hash", process.env.REACT_APP_GIT_HASH);
    }
  }, [config, version]);
}
