import graphql from "babel-plugin-relay/macro";

export const RunBindingMutation = graphql`
  mutation RunBindingMutation($input: RunBindingInput!) {
    runBinding(input: $input) {
      binding {
        name
        uuid
      }
    }
  }
`;
