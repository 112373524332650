import { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";

import ExportCsv from "app/components/csv-export/ExportCsv";
import TableActionMenu from "app/components/TableActionMenu";

import type {
  FilterBooleanOperator,
  FindingsMenuQuery,
} from "./__generated__/FindingsMenuQuery.graphql";

interface Props {
  dashboardKey: string;
  dashboardSectionKey?: string;
}

export function FindingsMenu({ dashboardKey, dashboardSectionKey }: Props) {
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);

  const filterElement = dashboardSectionKey
    ? {
        multiple: {
          operator: "AND" as FilterBooleanOperator,
          operands: [
            {
              single: {
                name: "dashboard-key",
                operator: "equals",
                value: dashboardKey,
              },
            },
            {
              single: {
                name: "dashboard-section-id",
                operator: "equals",
                value: dashboardSectionKey,
              },
            },
          ],
        },
      }
    : {
        single: {
          name: "dashboard-key",
          operator: "equals",
          value: dashboardKey,
        },
      };

  const data = useLazyLoadQuery<FindingsMenuQuery>(
    graphql`
      query FindingsMenuQuery($filterElement: FilterElementInput) {
        dashboardSectionResources(filterElement: $filterElement) {
          pageInfo {
            total
          }
        }
      }
    `,
    {
      filterElement,
    },
  );

  const isCostDashboard = dashboardKey.includes("cost");

  const columnsCsvExport = [
    { name: "Name", path: "resource.key" },
    { name: "Resource Type", path: "resource.resourceType" },

    { name: "Region", path: "resource.region" },
    { name: "Account Name", path: "resource.account.name" },

    {
      name: "Owner",
      path: "resource.tags",
      subpath: "[?key=='Owner'].value | [0]",
    },
    { name: "Tags", path: "resource.tags" },
    ...(isCostDashboard
      ? [
          { name: "Cost Total", path: "resource.cost.totalCost" },
          { name: "Cost Days", path: "resource.cost.totalDays" },
          { name: "Cost Multiplier", path: "resource.cost.multiplier" },
          { name: "Cost Currency", path: "resource.cost.currency" },
        ]
      : []),
  ];

  const extraPaths = ["resource.tags.key", "resource.tags.value"];
  const exportParams = [
    {
      name: "filterElement",
      type: "FilterElementInput",
      valueJSON: JSON.stringify(filterElement),
    },
  ];
  const rowCount = data.dashboardSectionResources.pageInfo.total ?? 0;

  return (
    <>
      {exportInProgress ? (
        <ExportCsv
          columns={columnsCsvExport}
          exportInProgress={exportInProgress}
          extraPaths={extraPaths}
          field="dashboardSectionResources"
          markComplete={() => setExportInProgress(false)}
          params={exportParams}
          rowCount={rowCount}
        />
      ) : null}
      <TableActionMenu>
        <MenuItem
          aria-label="export findings csv"
          onClick={() => setExportInProgress(true)}
        >
          export findings
        </MenuItem>
      </TableActionMenu>
    </>
  );
}
