import CloudCustodianLogo from "../../assets/cloudcustodian.svg?react";
import CloudFormationLogo from "../../assets/cloudformation.svg?react";
import CodeLogo from "../../assets/code.svg?react";
import TerraformLogo from "../../assets/terraform.svg?react";
import { Box, Tooltip } from "@mui/material";

interface Props {
  manager: string;
  size?: number;
}

export function ResourceManagerIcon({ manager, size = 15 }: Props) {
  let Logo;

  if (manager.toLocaleLowerCase().includes("cloudcustodian")) {
    Logo = CloudCustodianLogo;
  } else if (manager.toLocaleLowerCase().includes("cloudformation")) {
    Logo = CloudFormationLogo;
  } else if (manager.toLocaleLowerCase().includes("terraform")) {
    Logo = TerraformLogo;
  } else {
    Logo = CodeLogo;
  }

  const id = `tooltip-${manager}-${Date.now().toString(36)}`;

  return (
    <Box
      sx={{
        display: "flex",
        height: `${size}px`,
        width: `${size}px`,
      }}
    >
      <Tooltip id={id} title={manager}>
        <span>
          <Logo aria-labelledby={id} height={size} width={size} />
        </span>
      </Tooltip>
    </Box>
  );
}
