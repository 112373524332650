import type { ResultSet } from "@cubejs-client/core";

export default function dataFromResultSet(
  resultSet: ResultSet,
): { key: string; value: number }[] {
  const resultsetRows = resultSet.totalRow();
  return resultSet.seriesNames().map((item) => ({
    key: item.key,
    value: resultsetRows[item.key],
  }));
}
