import graphql from "babel-plugin-relay/macro";

export const RemovePolicyCollectionMutation = graphql`
  mutation RemovePolicyCollectionMutation($uuid: String!) {
    removePolicyCollection(uuid: $uuid) {
      collection {
        id @deleteRecord
      }
    }
  }
`;
