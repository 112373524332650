import Typography from "@mui/material/Typography";
import { COLORS } from "@stacklet/ui";

export interface Props {
  data: {
    key: string;
    value: number;
  }[];
  title?: string;
  context?: string;
  multiLine?: boolean;
}

export default function ValueChart({
  data,
  title,
  context = "",
  multiLine = true,
}: Props): JSX.Element | null {
  return (
    <>
      {data.map((s) => (
        <div key={s.key}>
          {multiLine ? (
            <div>
              {title ? (
                <Typography
                  component="div"
                  sx={{
                    color: COLORS.navy.L30,
                    fontSize: (theme) => theme.typography.pxToRem(10),
                    fontWeight: 800,
                  }}
                  gutterBottom
                >
                  {title}
                </Typography>
              ) : null}
              <Typography component="div" variant="body1" gutterBottom>
                {Math.round(s.value)}
                {context}
              </Typography>
            </div>
          ) : (
            <div style={{ background: COLORS.navy.L95, padding: "10px" }}>
              <Typography component="span">
                {Math.round(s.value)}
                {context}
              </Typography>
              {title ? (
                <Typography
                  component="span"
                  sx={{
                    color: COLORS.navy.L30,
                    fontSize: (theme) => theme.typography.pxToRem(10),
                    fontWeight: 800,
                  }}
                  gutterBottom
                >
                  {title}
                </Typography>
              ) : null}
            </div>
          )}
        </div>
      ))}
    </>
  );
}
