import graphql from "babel-plugin-relay/macro";

export const RemoveBindingMutation = graphql`
  mutation RemoveBindingMutation(
    $uuid: String!
    $names: [String!]!
    $connections: [ID!]!
  ) {
    removeBinding(uuid: $uuid) {
      binding {
        id @deleteRecord
      }
    }
    removeReportGroups(names: $names) @deleteEdge(connections: $connections)
  }
`;
