import { useContext } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Link } from "app/components";
import ToggleLinkGroup from "app/components/ToggleLinkGroup";
import { SideBarContext } from "app/contexts/SideBarContext";

const ChildWrapper = styled("span")(() => ({
  display: "flex",
  alignItems: "right",
}));

interface BreadcrumbElement {
  label?: string;
  target?: string;
  tabs?: ({ label: string; target: string } | undefined)[];
}

interface Props {
  breadcrumbs: BreadcrumbElement[];
  children?: React.ReactNode;
}

export default function BreadCrumbsHeader({
  breadcrumbs,
  children,
}: Props): JSX.Element {
  const { collapsed: isSideBarCollapsed } = useContext(SideBarContext);

  return (
    <Box marginBottom={9}>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: "#2C3640",
          color: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          left: (theme) => {
            return isSideBarCollapsed
              ? theme.sidebar.widthCollapsed
              : theme.sidebar.width;
          },
          width: "unset",
        }}
      >
        <Toolbar
          sx={{
            minHeight: {
              xs: "48px",
            },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ color: "white" }}>
              {breadcrumbs.map((breadCrumb, index) => {
                if (Array.isArray(breadCrumb.tabs)) {
                  return <ToggleLinkGroup key={index} tabs={breadCrumb.tabs} />;
                } else {
                  return breadCrumb.target ? (
                    <Link key={index} to={breadCrumb.target}>
                      <Box>{breadCrumb.label}</Box>
                    </Link>
                  ) : (
                    <Typography key={index}>{breadCrumb.label}</Typography>
                  );
                }
              })}
            </Breadcrumbs>
          </Box>
          {children ? <ChildWrapper>{children}</ChildWrapper> : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
