import graphql from "babel-plugin-relay/macro";

export const AddSlackProfileMutation = graphql`
  mutation AddSlackProfileMutation(
    $connections: [ID!]!
    $input: SlackConfigurationInput!
  ) {
    addSlackProfile(input: $input) {
      configuration
        @appendNode(
          connections: $connections
          edgeTypeName: "ConfigurationEdge"
        ) {
        id
        scope
        profile
        record {
          ... on SlackConfiguration {
            token
            webhooks {
              name
              url
            }
          }
        }
      }
    }
  }
`;
