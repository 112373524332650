import { useMemo } from "react";

import Box from "@mui/material/Box";
import graphql from "babel-plugin-relay/macro";
import { addMinutes, format, formatDistance } from "date-fns";
import { useFragment } from "react-relay/hooks";

import NoData from "app/components/empty-state/NoData";
import { TableLink } from "app/components/links";
import Table from "app/components/table/XGridTable";

import { BindingStatusBar } from "./BindingStatus";

import type { Runs_bindingRuns$key } from "./__generated__/Runs_bindingRuns.graphql";
import type { GridColDef } from "@mui/x-data-grid-pro";

interface Props {
  binding: Runs_bindingRuns$key;
}

export const BindingRunsFragment = graphql`
  fragment Runs_bindingRuns on Binding {
    runs {
      edges {
        node {
          runID
          startTime
          lastUpdateTime
          observedExecutionCount
          expectedExecutionCount
          executionErrorCount
        }
      }
    }
  }
`;

export function BindingRuns({ binding }: Props) {
  const data = useFragment(BindingRunsFragment, binding);
  const rows = useMemo(
    () =>
      (data?.runs?.edges ?? [])
        .filter((bindingRun) => bindingRun.node.startTime)
        .map((bindingRun) => {
          const {
            runID,
            startTime,
            lastUpdateTime,
            observedExecutionCount: complete,
            expectedExecutionCount: total,
            executionErrorCount: errors,
          } = bindingRun.node;
          const duration =
            startTime && lastUpdateTime
              ? formatDistance(new Date(lastUpdateTime), new Date(startTime))
              : startTime && total > 0
              ? "unknown"
              : "";
          return {
            runID: runID,
            startTime: startTime,
            duration: duration,
            progress: {
              savedExecutions: complete,
              expectedExecutions: total,
              progressPercent:
                complete == 0 || total == 0
                  ? 0
                  : Math.fround((complete / total) * 100),
              errorPercent:
                complete == 0 || total == 0
                  ? 0
                  : Math.fround((errors / total) * 100),
              elapsedTime: duration,
            },
            expectedExecutionCount: total,
            executionErrorCount: errors,
            observedExecutionCount: complete,
            remainingExecutionCount: total - complete,
          };
        }),
    [data?.runs],
  );

  type BindingRunRow = (typeof rows)[0];

  const columns: GridColDef<BindingRunRow>[] = useMemo(
    () => [
      {
        headerName: "Started (UTC)",
        field: "startTime",
        width: 175,
        renderCell: (params) => {
          const { runID, startTime } = params.row;
          return (
            <TableLink
              name={
                startTime
                  ? format(
                      addMinutes(
                        new Date(startTime),
                        new Date(startTime).getTimezoneOffset(),
                      ),
                      "PPp",
                    )
                  : "Not started"
              }
              to={`run/${runID}`}
            />
          );
        },
      },
      {
        headerName: "Total Run Time",
        field: "duration",
        width: 150,
      },
      {
        headerName: "Progress",
        field: "progress",
        width: 150,
        renderCell: (params) => {
          return (
            <Box sx={{ width: "90%" }}>
              <BindingStatusBar force={true} progress={params.row.progress} />
            </Box>
          );
        },
      },
      {
        headerName: "Total Executions",
        field: "expectedExecutionCount",
        width: 125,
      },
      {
        headerName: "Failed Executions",
        field: "executionErrorCount",
        width: 125,
        valueGetter: (params) =>
          params.row.executionErrorCount > 0
            ? params.row.executionErrorCount
            : "",
      },
      {
        headerName: "Remaining Executions",
        field: "remainingExecutionCount",
        width: 150,
        valueGetter: (params) =>
          params.row.remainingExecutionCount > 0
            ? params.row.remainingExecutionCount
            : "",
      },
    ],
    [],
  );

  if (rows.length == 0) {
    return (
      <Box mt={3}>
        <NoData message="No runs found" />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Table
        columns={columns}
        getRowId={(row) => row.runID}
        rowCount={rows?.length}
        rows={rows}
        tableId="admin-orchestration-binding-runs"
      />
    </Box>
  );
}
