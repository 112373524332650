import { WithCopyButton } from "@stacklet/ui";

import InlineIcon from "app/components/icons/InlineIcon";
import StatusIcon from "app/components/icons/StatusIcon";

import TooltipLink from "./TooltipLink";

interface CopyButtonWrapperProps {
  enabled: boolean;
  name: string;
  children: React.ReactNode;
}

const CopyButtonWrapper = ({
  enabled,
  name,
  children,
}: CopyButtonWrapperProps): JSX.Element =>
  enabled ? (
    <WithCopyButton value={name}>{children}</WithCopyButton>
  ) : (
    <>{children}</>
  );

interface Props {
  name: string;
  to: string;
  description?: string | null;
  errorMessage?: string;
  copyButton?: boolean;
  copyValue?: string;
}

export function TableLink({
  copyButton = false,
  description,
  errorMessage,
  name,
  to,
  copyValue,
}: Props) {
  if (errorMessage || description) {
    return (
      <CopyButtonWrapper enabled={copyButton} name={copyValue || name}>
        <InlineIcon>
          {errorMessage ? (
            <StatusIcon message={errorMessage} status="error" inline />
          ) : null}
          <TooltipLink description={description || ""} to={to}>
            {name}
          </TooltipLink>
        </InlineIcon>
      </CopyButtonWrapper>
    );
  }

  return (
    <CopyButtonWrapper enabled={copyButton} name={name}>
      <TooltipLink description={description || ""} to={to}>
        {name}
      </TooltipLink>
    </CopyButtonWrapper>
  );
}
