import graphql from "babel-plugin-relay/macro";

export const AddAWSOrganisationMutation = graphql`
  mutation AddAWSOrganisationMutation($input: AWSOrganisationInput!) {
    addAWSOrganisation(input: $input) {
      organisation {
        orgID
        name
        orgRole
        custodianRole
        memberRole
        externalID
      }
    }
  }
`;
