import graphql from "babel-plugin-relay/macro";

export const RemoveAccountMutation = graphql`
  mutation RemoveAccountMutation($key: String!, $provider: CloudProvider!) {
    removeAccount(key: $key, provider: $provider) {
      account {
        id @deleteRecord
      }
    }
  }
`;
