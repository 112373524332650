import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { styled } from "@mui/system";

import { COLORS } from "../../theme";

import type { TabsListProps } from "@mui/base";

const TabsList = styled(BaseTabsList)(
  () => `
    align-content: space-between;
    align-items: center;
    background-color: ${COLORS.navy.L10};
    border: 1px solid ${COLORS.navy.L10};
    border-radius: 0.125rem;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    width: fit-content;
    `
);

export function ToggleTabsList(props: TabsListProps) {
  return <TabsList {...props}>{props.children}</TabsList>;
}
