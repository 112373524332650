import graphql from "babel-plugin-relay/macro";

export const AddJiraProfileMutation = graphql`
  mutation AddJiraProfileMutation(
    $connections: [ID!]!
    $input: JiraConfigurationInput!
  ) {
    addJiraProfile(input: $input) {
      configuration
        @appendNode(
          connections: $connections
          edgeTypeName: "ConfigurationEdge"
        ) {
        profile
        record {
          ... on JiraConfiguration {
            apiKey
            projects {
              closedStatus
              issueType
              name
              project
            }
            url
            user
          }
        }
      }
    }
  }
`;
