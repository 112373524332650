import { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import graphql from "babel-plugin-relay/macro";
import { usePreloadedQuery } from "react-relay";

import type { QueryCSVExportQuery } from "./__generated__/QueryCSVExportQuery.graphql";
import type { PreloadedQuery } from "react-relay";

interface Props {
  queryRef: PreloadedQuery<QueryCSVExportQuery>;
  setExportId: React.Dispatch<React.SetStateAction<string>>;
  refetch: () => void;
  markComplete: () => void;
  rowCount: number;
}

const downloadCsv = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("data-testid", "download");
  a.download = url.split("/").pop() || "";
  document.body.appendChild(a);

  new Promise<void>((resolve) => {
    a.click();
    resolve();
  }).finally(() => {
    document.body.removeChild(a);
  });
};

export const csvExportQuery = graphql`
  query QueryCSVExportQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on ConnectionExport {
        completed
        downloadURL
        message
        processed
        success
      }
    }
  }
`;

export default function QueryCSVExport({
  queryRef,
  markComplete,
  rowCount,
  setExportId,
  refetch,
}: Props) {
  const csvExportData = usePreloadedQuery(csvExportQuery, queryRef);

  const [progressPercentage, setProgressPercent] = useState(0);

  useEffect(() => {
    if (
      csvExportData.node?.__typename === "ConnectionExport" &&
      csvExportData.node?.completed &&
      csvExportData.node.downloadURL
    ) {
      downloadCsv(csvExportData.node.downloadURL);
      setExportId("");
      markComplete();
    }
  }, [csvExportData, markComplete, setExportId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        csvExportData.node?.__typename === "ConnectionExport" &&
        !csvExportData.node?.completed
      ) {
        if (csvExportData.node?.success === false) {
          console.error(
            `Export failure message: ${csvExportData.node?.message}`,
          );
        }
        refetch();
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (
      csvExportData.node?.__typename === "ConnectionExport" &&
      csvExportData.node?.processed
    ) {
      const percentProcessed = (csvExportData.node.processed / rowCount) * 100;
      setProgressPercent(percentProcessed < 5 ? 5 : percentProcessed);
    }
  }, [csvExportData, rowCount]);

  return <LinearProgress value={progressPercentage} variant="determinate" />;
}
