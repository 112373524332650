import graphql from "babel-plugin-relay/macro";

export const UpdateEmailProfileMutation = graphql`
  mutation UpdateEmailProfileMutation($input: UpdateEmailConfigurationInput!) {
    updateEmailProfile(input: $input) {
      configuration {
        scope
        profile
        record {
          ... on EmailConfiguration {
            fromEmail
            smtp {
              server
              port
              ssl
              username
            }
            sesRegion
          }
        }
      }
    }
  }
`;
