import { Auth } from "aws-amplify";
import graphql from "babel-plugin-relay/macro";
import LZString from "lz-string";
import { commitLocalUpdate } from "react-relay";
import { getRequest, createOperationDescriptor } from "relay-runtime";

import getConfigs from "app/utils/configs";

import type { Environment } from "react-relay";

async function createUrlConfig(environment: Environment) {
  const configs = await getConfigs();
  const configDocs = configs.docs;
  const configRedash = configs.redash;
  const configSinistral = configs.sinistral;

  // Stacklet API
  const headers = {
    authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };

  const encodedExplorerURLState = LZString.compressToEncodedURIComponent(
    JSON.stringify({
      headers: JSON.stringify(headers),
    }),
  );

  const encodedConfig = encodeURIComponent(configs.deployment.gql_uri);

  const targetUri = `https://studio.apollographql.com/sandbox/explorer?endpoint=${encodedConfig}&explorerURLState=${encodedExplorerURLState}`;

  // Updating Relay Store
  commitLocalUpdate(environment, (store) => {
    const fieldKey = "UrlConfig";
    const __typename = "UrlConfig";

    const dataID = `client:${__typename}`;
    // only create UrlConfig record once
    if (store.get(dataID)) {
      return;
    }
    const record = store.create(dataID, __typename);

    record.setValue(configDocs?.docs_url, "docs");
    record.setValue(configRedash?.redash_uri, "redash");
    record.setValue(configSinistral?.sinistral_url, "sinistral");
    record.setValue(targetUri, "gql");

    const localDataQuery = graphql`
      query createUrlConfigsQuery {
        UrlConfig {
          __typename
        }
      }
    `;

    const request = getRequest(localDataQuery);
    const operation = createOperationDescriptor(request, {});
    environment.retain(operation);

    store.getRoot().setLinkedRecord(record, fieldKey);
  });
}

export default createUrlConfig;
