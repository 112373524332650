import type {} from "@mui/x-data-grid-pro/themeAugmentation";

import { checkboxClasses } from "@mui/material/Checkbox";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    sidebar: {
      widthSm?: string;
      width?: string;
      widthXL?: string;
      widthCollapsed?: string;
    };
    help: {
      widthSm?: string;
      width?: string;
      widthXL?: string;
      widthCollapsed?: string;
      breakpoint: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    sidebar?: {
      widthSm?: string;
      width?: string;
      widthXL?: string;
      widthCollapsed?: string;
    };
    help?: {
      widthSm?: string;
      width?: string;
      widthXL?: string;
      widthCollapsed?: string;
      breakpoint: number;
    };
  }
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    destructive: {
      main: string;
      light: string;
    };
    linkColor: string;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    small: true;
    xsmall: true;
  }
}

const BASE_FONT_SIZE = 16; // browser default

function pxToRem(px: number) {
  return `${px / BASE_FONT_SIZE}rem`;
}

//colors new
export const COLORS = {
  navy: {
    base: "#212B36",
    L10: "#2C3640",
    L20: "#424B54",
    L30: "#646B72",
    L40: "#858A90",
    L50: "#A6AAAF",
    L90: "#E9EAEB",
    L95: "#F4F4F5",
    D10: "#1F2933",
    D20: "#1C252E",
    D30: "#171E26",
    D40: "#12181E",
    D50: "#0D1116",
  },
  orange: {
    base: "#C05621",
    L10: "#C35E2C",
    L20: "#C96F42",
    L30: "#D38964",
    L40: "#DCA285",
    L50: "#E6BBA6",
    L70: "#ECCCBC",
    L80: "#F2DDD3",
    L90: "#F9EEE9",
    D10: "#B6521F",
    D20: "#A3491C",
    D30: "#863C17",
    D40: "#6A2F12",
    D50: "#4D220D",
  },
  darkOrange: {
    D20: "#85381C",
  },
  tan: {
    base: "#F1EDE9",
    D30: "#A9A6A3",
    D40: "#858280",
    D50: "#605F5D",
  },
  red: {
    base: "#E53E3E",
    L80: "#FAD8D8",
    L50: "#F5B2B2",
    L30: "#ED7878",
    D20: "#C33535",
  },
  cobalt: {
    base: "#1177BB",
    D20: "#2B7491",
    D30: "#245F77",
  },
  green: {
    D30: "#27714A",
  },
  yellow: {
    D20: "#D1BE50",
    D40: "#877B34",
  },
};

export const TOOLTIP_BG_DARK = COLORS.navy.L10;

export const CATEGORICAL_CHART_COLORS = [
  COLORS.orange.D10,
  COLORS.cobalt.D20,
  COLORS.green.D30,
  COLORS.navy.L20,
  COLORS.tan.D30,
  COLORS.darkOrange.D20,
  COLORS.red.D20,
  COLORS.yellow.D20,
];

const palette = {
  primary: { main: COLORS.orange.base, light: COLORS.orange.L80 },
  secondary: { main: COLORS.navy.base, dark: COLORS.navy.D20 },
  background: {
    default: "#f9f7f5",
  },
  action: {
    hover: "rgba(0, 0, 0, 0.04)",
  },
  text: {
    primary: COLORS.navy.base,
    secondary: COLORS.navy.L30,
  },
  destructive: {
    main: COLORS.red.base,
    light: COLORS.red.L80,
  },
  linkColor: COLORS.cobalt.D30,
};

const typography = {
  fontFamily: ["Overpass Variable", "sans-serif"].join(","),
  h1: {
    fontSize: pxToRem(36),
    fontWeight: 900,
    marginBottom: 16,
  },
  h2: {
    fontSize: pxToRem(24),
    fontWeight: 900,
    marginBottom: 16,
  },
  h3: {
    fontSize: pxToRem(18),
    fontWeight: 900,
  },
  h4: {
    fontSize: pxToRem(18),
    fontWeight: 400,
  },
  h5: {
    fontWeight: 700,
    fontSize: pxToRem(16),
  },
  h6: {
    fontSize: pxToRem(14),
    fontWeight: 600,
  },
  body1: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body2: {
    fontSize: pxToRem(16),
  },
  body3: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
  },
  subtitle1: {
    fontSize: pxToRem(18),
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: pxToRem(16),
    fontWeight: 600,
  },
  small: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  xsmall: {
    fontSize: pxToRem(10),
    fontWeight: 400,
  },
};

export const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: COLORS.navy.D50,
            [`&.${checkboxClasses.checked}`]: {
              color: COLORS.navy.D50,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: "8px",
            height: "28px",
            textTransform: "none",
            borderRadius: "2px",
            "&.Mui-disabled": {
              "&.MuiButton-containedPrimary": {
                backgroundColor: COLORS.orange.L70,
                color: "white",
              },
              "&.MuiButton-outlinedPrimary": {
                borderColor: COLORS.orange.L70,
                color: COLORS.orange.L70,
              },
              "&.MuiButton-outlinedSecondary": {
                borderColor: "#CBCBCB",
                color: "#CBCBCB",
              },
              "&.MuiButton-textError": {
                color: COLORS.red.L50,
              },
            },
          },
          outlined: {
            backgroundColor: "white",
            padding: "8px",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.MuiButton-root": {
              textTransform: "none",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            height: "42px",
            minHeight: "42px",
          },
          indicator: {
            width: "100%",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: COLORS.navy.base,
            padding: 0,
            textTransform: "none",
            minWidth: 0,
            "&.Mui-selected": {
              fontWeight: "bold",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            fontWeight: 800,
            fontSize: pxToRem(10),
            lineHeight: "150%",
            letterSpacing: "0.04em",
            textTransform: "uppercase",
          },
          columnHeaderTitle: {
            fontWeight: 800,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: "inherit",
            boxShadow: "none",
            borderBottom: "1px solid rgba(0, 0, 0, .125)",
            "&$expanded": {
              margin: "auto",
            },
            "&:before": {
              display: "none",
            },
          },
          expanded: {},
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.default,
            borderBottom: "1px solid rgba(0, 0, 0, .125)",
            marginBottom: -1,
            minHeight: 56,
          },
          content: {
            margin: "12px 0",
          },
          expanded: {},
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2),
            flexDirection: "column",
          }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.spacing(0.5),
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 900,
            fontSize: pxToRem(24),
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&.MuiButtonBase-root": {
              display: "flex",
              padding: theme.spacing(0.5, 1.5),
              justifyContent: "flex-start",
            },
          }),
        },
      },
    },
    palette,
    typography,
    sidebar: {
      widthSm: "150px",
      width: "256px",
      widthXL: "256px",
      widthCollapsed: "64px",
    },
    help: {
      widthSm: "50%",
      width: "300px",
      widthXL: "500px",
      widthCollapsed: "0px",
      breakpoint: 1440,
    },
  })
);
