import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { COLORS } from "@stacklet/ui";

interface Props {
  title: string;
  message: string;
}

export default function TextCard({ title, message }: Props): JSX.Element {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ background: COLORS.navy.L95, mt: 2, p: 2 }}>
        <Typography textAlign="center" variant="body1">
          {message}
        </Typography>
      </Box>
    </Box>
  );
}
