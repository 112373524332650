import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import * as Sentry from "@sentry/browser";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation } from "react-relay";

import { UpsertUserDataMutation } from "app/mutations";

import type { PreferencesDialogQuery } from "./__generated__/PreferencesDialogQuery.graphql";
import type { UpsertUserDataMutation as UpsertUserDataMutationType } from "app/mutations/__generated__/UpsertUserDataMutation.graphql";

interface Props {
  openDialog(arg: boolean): void;
  isDialogOpen: boolean;
}

export function PreferencesDialog({
  openDialog,
  isDialogOpen,
}: Props): JSX.Element {
  const data = useLazyLoadQuery<PreferencesDialogQuery>(
    graphql`
      query PreferencesDialogQuery($filterElement: FilterElementInput) {
        userData(filterElement: $filterElement) {
          edges {
            node {
              value
            }
          }
        }
      }
    `,
    {
      filterElement: {
        single: {
          name: "name",
          operator: "equals",
          value: "analytics_enabled",
        },
      },
    },
  );

  const analyticsEnabled = Boolean(
    (data.userData.edges ?? []).find((edge) => edge.node.value === "true"),
  );

  const toggleAnalytics = () =>
    commit({
      variables: {
        input: {
          name: "analytics_enabled",
          type: "config",
          value: String(!analyticsEnabled),
        },
      },
      onError: (err) => {
        Sentry.captureException(err);
      },
    });

  const [commit, isInFlight] = useMutation<UpsertUserDataMutationType>(
    UpsertUserDataMutation,
  );

  return (
    <Dialog
      aria-labelledby="form-dialog-preferences"
      maxWidth="md"
      onClose={() => {
        openDialog(false);
      }}
      open={isDialogOpen}
      sx={{ p: 3 }}
      fullWidth
    >
      <DialogTitle
        className="dialogTitle"
        data-testid="preferences-dialog"
        id="form-dialog-preferences"
      >
        Preferences
      </DialogTitle>

      <DialogContent sx={{ pt: 3 }}>
        <Box display="flex" justifyContent="space-between">
          <span>
            Enable analytics to shape improvements to the Stacklet console
          </span>
          {isInFlight ? (
            <CircularProgress size={20} />
          ) : (
            <Switch
              checked={analyticsEnabled}
              color="primary"
              onChange={toggleAnalytics}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
