import { forwardRef } from "react";

import { Box, CircularProgress } from "@mui/material";
import { Button as MuiButton } from "@mui/material";

import type { ButtonProps } from "@mui/material";

type ButtonType = "main" | "outline-main" | "tertiary" | "destructive";
type Variant = "text" | "contained" | "outlined" | undefined;
type Color = "inherit" | "primary" | "secondary" | "error";
type CircularProgressColor = "default" | "primary" | "secondary";

interface Props extends ButtonProps {
  buttonType: ButtonType;
  className?: string;
  isDisabled?: boolean;
  loading?: {
    isLoading: boolean;
    loadingMessage: string;
  };
  dataTestID?: string;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      buttonType,
      className,
      isDisabled,
      loading,
      dataTestID,
      children,
      ...props
    },
    ref
  ) => {
    let variant: Variant;
    let color: Color;
    const classNames = className || "";
    let circularProgressColor: CircularProgressColor = "default";

    switch (buttonType) {
      case "main":
        variant = "contained";
        color = "primary";
        circularProgressColor = "primary";
        break;
      case "outline-main":
        variant = "outlined";
        color = "primary";
        circularProgressColor = "primary";
        break;
      case "tertiary":
        variant = "outlined";
        color = "secondary";
        circularProgressColor = "primary";
        break;
      case "destructive":
        color = "error";
        circularProgressColor = "primary";
        break;
    }

    return (
      <MuiButton
        className={classNames}
        color={color}
        data-testid={dataTestID}
        disabled={isDisabled}
        ref={ref}
        variant={variant}
        {...props}
      >
        {loading?.isLoading ? (
          <Box alignItems="center" display="flex" mr={1}>
            <CircularProgress
              color={circularProgressColor}
              size={15}
              sx={
                buttonType === "main"
                  ? {
                      color: "white",
                    }
                  : undefined
              }
            />
          </Box>
        ) : null}
        {loading?.isLoading ? loading.loadingMessage : children}
      </MuiButton>
    );
  }
);
