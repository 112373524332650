/**
 * @generated SignedSource<<48648d89d7218fb99a6ead94375040d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SupportQuery$variables = Record<PropertyKey, never>;
export type SupportQuery$data = {
  readonly whoami: {
    readonly displayName: string | null | undefined;
    readonly email: string | null | undefined;
  };
};
export type SupportQuery = {
  response: SupportQuery$data;
  variables: SupportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrentUser",
    "kind": "LinkedField",
    "name": "whoami",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SupportQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "774f24c4b7bb4ee151342863a1364ce6",
    "id": null,
    "metadata": {},
    "name": "SupportQuery",
    "operationKind": "query",
    "text": "query SupportQuery {\n  whoami {\n    email\n    displayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "434735a05c727281c5a8b4f5fda5dacf";

export default node;
