import graphql from "babel-plugin-relay/macro";

export const UpdateSlackProfileMutation = graphql`
  mutation UpdateSlackProfileMutation($input: UpdateSlackProfileInput!) {
    updateSlackProfile(input: $input) {
      configuration {
        profile
        record {
          ... on SlackConfiguration {
            token
            webhooks {
              name
              url
            }
          }
        }
      }
    }
  }
`;
