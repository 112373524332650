import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import styled from "@mui/system/styled";

import { COLORS } from "../../theme";

import type { TabProps } from "@mui/base";

const Tab = styled(BaseTab)`
  background-color: white;
  border: none;
  color: ${COLORS.navy.L10};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  width: 100%;
  white-space: nowrap;

  &.${tabClasses.selected} {
    background: ${COLORS.navy.L10};
    color: white;
  }
`;

export function ToggleTab(props: TabProps) {
  return <Tab {...props}>{props.children}</Tab>;
}
