import graphql from "babel-plugin-relay/macro";

export const UpsertReportGroupsMutation = graphql`
  mutation UpsertReportGroupsMutation(
    $connections: [ID!]!
    $input: UpsertReportGroupsInput
  ) {
    upsertReportGroups(input: $input) {
      reportGroups
        @appendNode(
          connections: $connections
          edgeTypeName: "ReportGroupEdge"
        ) {
        deliverySettings {
          ... on EmailSettings {
            firstMatchOnly
            recipients {
              value
              account_owner
              resource_owner
              event_owner
            }
            type
            template
          }
          ... on SlackSettings {
            firstMatchOnly
            type
            recipients {
              value
              account_owner
              resource_owner
              event_owner
            }
            type
            template
          }
          ... on JiraSettings {
            firstMatchOnly
            type
            recipients {
              value
              tag
              account_owner
              resource_owner
              event_owner
            }
            project
            template
          }
          ... on ServiceNowSettings {
            firstMatchOnly
            impact
            type
            recipients {
              value
              tag
              account_owner
              resource_owner
              event_owner
            }
            template
            urgency
            shortDescription
          }
        }
        id
        enabled
        name
        groupBy
        schedule
      }
    }
  }
`;
