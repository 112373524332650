import { RectangleSkeleton } from "../skeletons";
import { BackdropFallback } from "./BackdropFallback";

interface Props {
  height?: string;
  width?: string;
}

export function DialogFallback({
  height = "90vh",
  width = "600px",
}: Props): JSX.Element {
  return (
    <BackdropFallback>
      <RectangleSkeleton height={height} width={width} />
    </BackdropFallback>
  );
}
