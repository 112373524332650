import graphql from "babel-plugin-relay/macro";

export const UpdateJiraProfileMutation = graphql`
  mutation UpdateJiraProfileMutation($input: JiraConfigurationInput!) {
    addJiraProfile(input: $input) {
      configuration {
        profile
        record {
          ... on JiraConfiguration {
            apiKey
            projects {
              closedStatus
              issueType
              name
              project
            }
            url
            user
          }
        }
      }
    }
  }
`;
