import graphql from "babel-plugin-relay/macro";

export const UpdateAccountGroupMutation = graphql`
  mutation UpdateAccountGroupMutation($input: UpdateAccountGroupInput!) {
    updateAccountGroup(input: $input) {
      group {
        id
        name
        description
        dynamicFilter
        regions
        items {
          uuid
          key
          provider
          name
          dynamic
        }
      }
    }
  }
`;
