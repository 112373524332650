import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ResponsiveBar } from "@nivo/bar";
import { CATEGORICAL_CHART_COLORS, COLORS } from "@stacklet/ui";

import type { Props as ValueTooltip } from "./ValueTooltip";
import type { ChartPivotRow } from "@cubejs-client/core";
import type { AxisProps } from "@nivo/axes";
import type { BarCustomLayerProps } from "@nivo/bar";
import type { Datum } from "@nivo/legends";

interface Props {
  title: string;
  subtitle: string;
  data: ChartPivotRow[];
  keys: string[];
  xAxisFormat?: AxisProps["format"];
  grouped?: boolean;
  enableLabel?: boolean;
  tooltip?: React.FC<ValueTooltip>;
  BarLegend?: React.FC<BarCustomLayerProps<ChartPivotRow>>;
  legendData?: Datum[];
  chartLeftMargin?: number;
  wrapperTestID?: string;
  barColors?: string[];
}

export default function Bar({
  data,
  title,
  subtitle,
  keys,
  xAxisFormat,
  grouped = false,
  enableLabel = false,
  tooltip,
  BarLegend,
  chartLeftMargin = 55,
  wrapperTestID = "barchart-wrapper",
  barColors = CATEGORICAL_CHART_COLORS,
  legendData,
}: Props) {
  return (
    <Box data-testid={wrapperTestID}>
      <Box sx={{ marginBottom: 1 }}>
        <Typography variant="h6">{title}</Typography>
      </Box>

      <Typography
        color={COLORS.navy.L30}
        sx={{
          opacity: 0.8,
          textTransform: "uppercase",
          fontWeight: 800,
        }}
        variant="xsmall"
      >
        {subtitle}
      </Typography>

      <Box
        sx={{
          height: "300px",
        }}
      >
        <ResponsiveBar
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            format: xAxisFormat,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisRight={null}
          axisTop={null}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          colors={barColors}
          data={data}
          enableLabel={enableLabel}
          groupMode={grouped ? "grouped" : "stacked"}
          indexBy="x"
          indexScale={{ type: "band", round: true }}
          keys={keys}
          labelSkipHeight={12}
          labelSkipWidth={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1]] }}
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            BarLegend ? BarLegend : "legends",
          ]}
          legends={[
            {
              dataFrom: "keys",
              data: legendData,
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 5,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          margin={{ top: 20, right: 130, bottom: 45, left: chartLeftMargin }}
          padding={0.3}
          role="application"
          tooltip={tooltip}
          valueScale={{ type: "linear" }}
        />
      </Box>
    </Box>
  );
}
