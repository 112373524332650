import graphql from "babel-plugin-relay/macro";

export const AddUserMutation = graphql`
  mutation AddUserMutation($input: AddUserInput!, $connections: [ID!]!) {
    addUser(input: $input) {
      user @appendNode(connections: $connections, edgeTypeName: "UserEdge") {
        id
        key
        username
        name
        email
        allRoles
        assignedRoles
      }
    }
  }
`;
