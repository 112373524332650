import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ResponsiveChoropleth } from "@nivo/geo";
import { COLORS } from "@stacklet/ui";

import countries from "./world-countries";

import type { ChoroplethTooltip } from "@nivo/geo";

interface Props {
  title: string;
  data: {
    id: string | number | boolean; // iso code
    value: string | number | boolean;
    regions: {
      region: string | number | boolean;
      count: string | number | boolean;
    }[];
  }[];
  tooltip?: ChoroplethTooltip;
  wrapperTestId?: string;
}

export default function Choropleth({
  title,
  data,
  wrapperTestId = "",
  tooltip,
}: Props): JSX.Element {
  return (
    <Box data-testid={wrapperTestId}>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box sx={{ height: 300 }}>
        <ResponsiveChoropleth
          borderColor={COLORS.navy.D10}
          borderWidth={0.5}
          colors="PuBu"
          data={data}
          domain={[0, 10000]}
          enableGraticule={true}
          features={countries.features}
          graticuleLineColor={COLORS.tan.base}
          label="properties.name"
          legends={[]}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          projectionRotation={[0, 0, 0]}
          projectionScale={70}
          projectionTranslation={[0.5, 0.5]}
          tooltip={tooltip}
          unknownColor={COLORS.tan.D40}
        />
      </Box>
    </Box>
  );
}
