/**
 * @generated SignedSource<<7a49622a51ca06eb1f97786ca9ea3ef4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeployBindingMutation$variables = {
  uuid: string;
};
export type DeployBindingMutation$data = {
  readonly deployBinding: {
    readonly binding: {
      readonly name: string;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type DeployBindingMutation = {
  response: DeployBindingMutation$data;
  variables: DeployBindingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeployBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BindingPayload",
        "kind": "LinkedField",
        "name": "deployBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeployBindingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BindingPayload",
        "kind": "LinkedField",
        "name": "deployBinding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Binding",
            "kind": "LinkedField",
            "name": "binding",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f115ae28deff18bc937e181a1714dc9",
    "id": null,
    "metadata": {},
    "name": "DeployBindingMutation",
    "operationKind": "mutation",
    "text": "mutation DeployBindingMutation(\n  $uuid: String!\n) {\n  deployBinding(uuid: $uuid) {\n    binding {\n      uuid\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebf78b4937ab78db332cf683a38e64f5";

export default node;
