import graphql from "babel-plugin-relay/macro";

export const RemoveUserMutation = graphql`
  mutation RemoveUserMutation($key: Int!) {
    removeUser(key: $key) {
      removed {
        id @deleteRecord
      }
    }
  }
`;
