import { useContext } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as DOMPurify from "dompurify";

import RectangleSkeleton from "app/components/skeletons/RectangleSkeleton";
import { HelpContext } from "app/contexts/HelpContext";
import { useHelpContent } from "app/hooks";

interface HelpProps {
  pageNames: string[];
}

export default function Help({ pageNames }: HelpProps): JSX.Element {
  const theme = useTheme();
  const helpContext = useContext(HelpContext);
  const [isLoading, helpContent] = useHelpContent(pageNames);

  return (
    <Drawer
      SlideProps={{
        timeout: {
          enter: 0,
          exit: 0,
        },
      }}
      anchor="right"
      data-testid="help-drawer"
      open={!helpContext.collapsed}
      sx={{
        flexShrink: 0,
        width: {
          xs: theme.help.widthSm,
          md: theme.help.width,
          xl: theme.help.widthXL,
        },
        "& .MuiDrawer-paper ": {
          width: {
            xs: theme.help.widthSm,
            md: theme.help.width,
            xl: theme.help.widthXL,
          },
          ...(!helpContext.collapsed && {
            transform: "none !important",
          }),
        },
      }}
      variant={
        window.innerWidth < theme.help.breakpoint ? "temporary" : "persistent"
      }
    >
      <Toolbar
        sx={{
          minHeight: 94,
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
        }}
      />
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 2, 3),
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
        }}
      >
        <Typography variant="h2">Help</Typography>
        <MuiLink
          color="inherit"
          component="button"
          onClick={helpContext.collapse}
          sx={{
            color: "text.secondary",
          }}
          variant="body2"
        >
          Close
        </MuiLink>
      </Box>
      {isLoading ? (
        <RectangleSkeleton height="100%" />
      ) : (
        <Box
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(helpContent) }}
          sx={{ padding: 2 }}
        />
      )}
      <Toolbar
        sx={{
          minHeight: 76,
        }}
      />
    </Drawer>
  );
}
