import Box from "@mui/material/Box";
import { format } from "date-fns";

import type { ChartPivotRow } from "@cubejs-client/core";
import type { BarTooltipProps } from "@nivo/bar";

export interface Props extends BarTooltipProps<ChartPivotRow> {
  displayId?: boolean;
}

export default function ValueTooltip({
  color,
  indexValue,
  id,
  value,
  displayId = false,
}: Props): JSX.Element {
  let formattedIndexValue;
  if (typeof indexValue === "string" && !isNaN(Date.parse(indexValue))) {
    formattedIndexValue = format(new Date(indexValue), "MMM d");
  }

  return (
    <Box
      sx={{
        padding: "5px 9px",
        backgroundColor: "white",
        borderRadius: "2px",
        color: "inherit",
        fontSize: (theme) => theme.typography.pxToRem(13),
        boxShadow: "rgb(0 0 0 / 25%) 0px 1px 2px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: `${color}`,
          marginRight: "7px",
        }}
      />
      {displayId ? (
        <>
          <span>
            {typeof id === "string" && id.includes(",") ? id.split(",")[0] : id}
          </span>
          <Box px="4px">-</Box>
        </>
      ) : null}
      <span>{formattedIndexValue || indexValue}</span>
      <Box px="4px">:</Box>
      <strong>{value}</strong>
    </Box>
  );
}
