import { useState } from "react";

import { nanoid } from "nanoid";

interface FormError {
  id: string;
  message: string;
}

export function useFormErrors(): [FormError[], (messages: string[]) => void] {
  const [errors, setErrors] = useState<FormError[]>([]);

  const setFormErrors = (messages: string[]) =>
    setErrors(messages.map((msg) => ({ id: nanoid(), message: msg })));

  return [errors, setFormErrors];
}
