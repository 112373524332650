/**
 * @generated SignedSource<<adffcc9604a2eaba37181f58e03d38f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FooterQuery$variables = Record<PropertyKey, never>;
export type FooterQuery$data = {
  readonly platform: {
    readonly " $fragmentSpreads": FragmentRefs<"AppVersion_version">;
  };
};
export type FooterQuery = {
  response: FooterQuery$data;
  variables: FooterQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FooterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Platform",
        "kind": "LinkedField",
        "name": "platform",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppVersion_version"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FooterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Platform",
        "kind": "LinkedField",
        "name": "platform",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cloudCustodianVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac755b2c6fe2111e8ab9f727acd26614",
    "id": null,
    "metadata": {},
    "name": "FooterQuery",
    "operationKind": "query",
    "text": "query FooterQuery {\n  platform {\n    ...AppVersion_version\n    id\n  }\n}\n\nfragment AppVersion_version on Platform {\n  cloudCustodianVersion\n  version\n}\n"
  }
};

(node as any).hash = "903a1fb185aaacf711e259e3ade37517";

export default node;
