import { useEffect, useState } from "react";

import { nanoid } from "nanoid";
import { useLazyLoadQuery } from "react-relay";

import type { GraphQLTaggedNode } from "react-relay";
import type { OperationType } from "relay-runtime";

// useLazyLoadQuery factory which ensures a network request is made
// for every initial component render
export function useLazyLoadFetchQuery<T extends OperationType>(
  query: GraphQLTaggedNode,
  variables: Record<string, unknown>,
): T["response"] {
  const [fetchKey, setFetchKey] = useState<string>("");

  useEffect(() => {
    setFetchKey(nanoid());
  }, []);

  return useLazyLoadQuery<T>(query, variables, {
    fetchKey,
    fetchPolicy: "store-and-network",
  });
}
