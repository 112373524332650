import { createContext, useContext, useEffect, useState } from "react";

import { z } from "zod";

interface Props {
  children: React.ReactNode;
}

const ConfigSchema = z.object({
  analytics_configurable: z.boolean(),
  cloud_providers: z.array(z.enum(["aws", "azure", "gcp", "tencentcloud"])),
  commshub_enabled: z.boolean(),
  csv_export: z.boolean(),
  customer: z.string(),
  dashboards: z.array(z.enum(["cubejs", "cis", "soc2", "cost"])),
  data_retention: z.number(),
  functional_test: z.boolean(),
  graphql_playground: z.boolean(),
  hotjar_enabled: z.boolean(),
  is_poc: z.boolean(),
  private_instance: z.boolean(),
  resource_explorer_enabled: z.boolean(),
  segment_write_key: z.string(),
  sentry_enabled: z.boolean(),
  initiatives_enabled: z.boolean(),
  system_dashboards: z.boolean(),
  activity_views_enabled: z.boolean(),
});

export type AppConfig = z.infer<typeof ConfigSchema>;

const ConfigContext = createContext<AppConfig | null>(null);

export async function fetchConfig(): Promise<AppConfig> {
  const response = await fetch(process.env["REACT_APP_CONFIG_ENDPOINT"]);

  if (!response.ok) {
    const message = `An error has occured fetching application configuration: ${response.status}`;
    throw new Error(message);
  }

  const config = await response.json();
  ConfigSchema.parse(config);

  return config;
}

export function ConfigProvider({ children }: Props): JSX.Element {
  const [config, setConfig] = useState<AppConfig | null>(null);

  useEffect(() => {
    const getConfig = async () => {
      const config = await fetchConfig().catch((error: Error) =>
        console.error(error),
      );
      if (config) {
        setConfig(config);
      }
    };

    getConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

export const useConfig = () => {
  return useContext(ConfigContext);
};
