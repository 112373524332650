import graphql from "babel-plugin-relay/macro";

export const UpdateRepositoryMutation = graphql`
  mutation UpdateRepositoryMutation($input: UpdateRepositoryInput!) {
    updateRepository(input: $input) {
      repository {
        name
        url
        authUser
        branchName
        description
        policyDirectories
        policyFileSuffix
        hasAuthToken
        hasSshPassphrase
        hasSshPrivateKey
      }
    }
  }
`;
