import { Box, Chip, Typography, styled } from "@mui/material";
import { format } from "date-fns";

import ChangelogIcon from "../../assets/commit_icon.svg?react";
import { COLORS } from "../../theme";
import { WithCopyButton } from "../WithCopyButton";

const VerticalLine = styled("div")({
  borderLeft: "1px solid #BCBFC3",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 35,
  zIndex: 1,
});

interface Props {
  timestamp: Date;
  title: React.ReactNode | string;
  action?: string;
  actionMsg?: string;
  copyValue?: string;
  selected?: boolean;
  children?: React.ReactNode;
}

export function ChangelogCard({
  timestamp,
  copyValue,
  title,
  action,
  actionMsg = "",
  selected = false,
  children,
}: Props) {
  const eventDate = format(new Date(timestamp), "MMMM dd, yyyy");
  const eventTime = format(new Date(timestamp), "hh:mm aa");

  return (
    <Box
      aria-label="Changelog card"
      sx={{
        backgroundColor: "common.white",
        pb: 3,
        pl: 3,
        position: "relative",
      }}
    >
      <VerticalLine />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: 1,
          mx: 0,
          position: "relative",
          zIndex: 10,
        }}
      >
        <ChangelogIcon />
        <Typography
          color={COLORS.navy.L20}
          ml={1}
          sx={{ fontWeight: 700, textTransform: "uppercase" }}
        >
          {eventDate}
        </Typography>
      </Box>
      <Box
        sx={{
          p: 2,
          border: "2px solid #F4F4F5",
          borderRadius: "4px",
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          backgroundColor: selected ? COLORS.navy.L95 : "common.white",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <div>
            {copyValue ? (
              <WithCopyButton value={copyValue}>{title}</WithCopyButton>
            ) : (
              <span>{title}</span>
            )}
          </div>

          <Typography color={COLORS.navy.L30} sx={{ fontWeight: 400 }}>
            {eventTime}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>{children}</Box>
        {action || actionMsg ? (
          <Box alignItems="center" display="flex" flexDirection="row">
            {action ? (
              <Chip
                className="action"
                label={action}
                size="small"
                sx={{
                  backgroundColor: COLORS.navy.L95,
                  fontSize: (theme) => theme.typography.pxToRem(12),
                  fontWeight: 800,
                  borderRadius: 1,
                  borderColor: COLORS.navy.L95,
                  textTransform: "uppercase",
                }}
                variant="outlined"
              />
            ) : null}

            {actionMsg ? (
              <Box ml={1}>
                <Typography sx={{ color: COLORS.navy.L30 }}>
                  {actionMsg}
                </Typography>
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
