import graphql from "babel-plugin-relay/macro";

export const UpdatePolicyCollectionMutation = graphql`
  mutation UpdatePolicyCollectionMutation(
    $input: UpdatePolicyCollectionInput!
  ) {
    updatePolicyCollection(input: $input) {
      collection {
        id
        uuid
        name
        description
        autoUpdate
        items {
          uuid
          name
          version
        }
      }
    }
  }
`;
