import { useCubeQuery } from "@cubejs-client/react";
import Skeleton from "@mui/material/Skeleton";
import * as Sentry from "@sentry/browser";

import {
  ChartErrorBoundary,
  Pie,
  TextCard,
} from "app/views/dashboards/components";

import type { Query } from "@cubejs-client/core";
import type { Props as PieChartProps } from "app/views/dashboards/components/charts/Pie";

interface Props extends Omit<PieChartProps, "data"> {
  query: Query;
  title: string;
}

export default function CubePieChart({
  query,
  title,
  ...rest
}: Props): JSX.Element | null {
  const { resultSet, isLoading, error } = useCubeQuery(query);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        height={300}
        role="progressbar"
        variant="rectangular"
      />
    );
  }

  if (error) {
    Sentry.captureException(error);
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return null;
  }

  const data = resultSet?.chartPivot();
  const itemKey = query?.measures ? query.measures[0] : "";
  const dataFormat = data.map((item) => ({
    id: item["x"],
    label: item["x"],
    value: item[itemKey],
  }));

  const hasValues =
    resultSet.series &&
    !!resultSet.series().length &&
    resultSet.series()[0].series.reduce((prev, curr) => prev + curr.value, 0);

  if (!data?.length || !hasValues) {
    return (
      <TextCard
        message={`No ${title.toLocaleLowerCase()}, keep up the great work! 🎉`}
        title={title}
      />
    );
  }

  return (
    <ChartErrorBoundary name={title.toLocaleLowerCase()}>
      <Pie
        data={dataFormat}
        title={title}
        wrapperTestId={`${title.replaceAll(" ", "-").toLowerCase()}-pie-chart`}
        {...rest}
      />
    </ChartErrorBoundary>
  );
}
