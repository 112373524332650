import type React from "react";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import type { AlertColor } from "@mui/material/Alert";

export interface AlertStatus {
  message: string;
  severity: AlertColor;
}

type Props = {
  alert: AlertStatus;
  openAlert: boolean;
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ApplicationAlert({
  alert,
  openAlert,
  setOpenAlert,
}: Props): JSX.Element {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={2000}
      onClose={() => setOpenAlert(false)}
      open={openAlert}
    >
      <Alert severity={alert.severity}>{alert.message}</Alert>
    </Snackbar>
  );
}
