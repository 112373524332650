import { CATEGORICAL_CHART_COLORS } from "@stacklet/ui";

import type { Datum } from "@nivo/legends";

export default function legendData(keys: string[]): Datum[] {
  return keys.map((key, index) => ({
    id: key,
    label:
      typeof key === "string" && key.includes(",") ? key.split(",")[0] : key,
    color: CATEGORICAL_CHART_COLORS[index],
  }));
}
