import graphql from "babel-plugin-relay/macro";

export const UpdateOwnerProfileMutation = graphql`
  mutation UpdateOwnerProfileMutation(
    $resourceOwnerProfileInput: ResourceOwnerConfigurationInput!
    $accountOwnerProfileInput: AccountOwnersConfigurationInput!
  ) {
    addResourceOwnerProfile(input: $resourceOwnerProfileInput) {
      configuration {
        profile
        record {
          ... on ResourceOwnerConfiguration {
            tags
            default
          }
        }
      }
    }
    addAccountOwnersProfile(input: $accountOwnerProfileInput) {
      configuration {
        profile
        record {
          ... on AccountOwnersConfiguration {
            tags
            default {
              owners
            }
          }
        }
      }
    }
  }
`;
