import { useFilterParams } from "../useFilterParams";

import type { Filter, GenericFilter, TagFilter } from "../useFilterParams";
import type {
  FilterBooleanOperator,
  FilterElementInput,
} from "app/views/cloudops/resource-matches/__generated__/ResourceMatchesTableRefetchQuery.graphql";

const isTag = (filter: Filter): filter is TagFilter[] =>
  typeof filter[0] !== "string" && "tagValue" in filter[0];

export const isResourceTagFilter = (
  key: string,
  filter: Filter,
): filter is TagFilter[] => key === "resource-tag" && isTag(filter);

export const isGenericFilter = (filter: Filter): filter is GenericFilter[] =>
  typeof filter[0] !== "string" && "value" in filter[0];

// translate filter form key/value into a FilterElementInput operand
export function translateOperand(
  key: string,
  value: Filter,
): FilterElementInput {
  if (isResourceTagFilter(key, value)) {
    if (value.length === 1) {
      return {
        single: {
          name: `resource-tag:${value[0].key}`,
          value: value[0].tagValue,
        },
      };
    } else {
      return {
        multiple: {
          operator: "AND" as FilterBooleanOperator,
          operands: value.map((tag) => ({
            single: { name: `resource-tag:${tag.key}`, value: tag.tagValue },
          })),
        },
      };
    }
  } else if (isGenericFilter(value) && key === "account") {
    // when provided an account value without a key, we query with the 'account-name' filter instead
    return formatOperand("account-name", value);
  }
  return formatOperand(key, value);
}

function formatOperand(key: string, value: GenericFilter[] | string[]) {
  if (value.length === 1) {
    if (typeof value[0] === "string") {
      return {
        single: {
          name: key,
          value: value[0],
        },
      };
    }
    return {
      single: {
        name: key,
        value: value[0].value,
      },
    };
  }
  return {
    multiple: {
      operator: "OR" as FilterBooleanOperator,
      operands: value.map((filter) => ({
        single: {
          name: key,
          value: typeof filter === "string" ? filter : filter.value,
        },
      })),
    },
  };
}

function formatOperands(
  operands: FilterElementInput[],
  operator: FilterBooleanOperator,
) {
  if (operands.length === 1) {
    return operands[0];
  }

  return {
    multiple: {
      operator,
      operands,
    },
  };
}

/**
 * Returns a FilterElementInput constructed from extra operands and filters from searchParams
 * @param {FilterElementInput[]} extraOperands - array of additional operands to include in FilterElementInput
 */
export function useFilterElement(
  extraOperands: FilterElementInput[] = [],
): FilterElementInput | null {
  const [filters] = useFilterParams();

  const operands = Object.entries(filters ?? {}).map(([key, value]) =>
    translateOperand(key, value),
  );

  const allOperands: FilterElementInput[] = [];

  if (operands.length) {
    allOperands.push(formatOperands(operands, "AND"));
  }

  if (extraOperands.length) {
    allOperands.push(formatOperands(extraOperands, "AND"));
  }

  if (!operands.length && !extraOperands.length) {
    return null;
  }

  return {
    multiple: {
      operator: "AND" as FilterBooleanOperator,
      operands: allOperands,
    },
  };
}
