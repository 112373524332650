import { useCubeQuery } from "@cubejs-client/react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { ResponsiveLine } from "@nivo/line";
import * as Sentry from "@sentry/browser";
import { CATEGORICAL_CHART_COLORS } from "@stacklet/ui";
import graphql from "babel-plugin-relay/macro";
import { format } from "date-fns";
import { useFragment } from "react-relay";
import { useDeepCompareMemo } from "use-deep-compare";

import type { Timeline_dashboard$key } from "./__generated__/Timeline_dashboard.graphql";
import type { Provider } from "app/contexts/ProviderContext";

const getColoredAxis = (color: string) => {
  return {
    axis: {
      legend: {
        text: {
          fill: color,
        },
      },
    },
  };
};

interface Props {
  dashboard: Timeline_dashboard$key;
}

export function Timeline({ dashboard }: Props): JSX.Element | null {
  const data = useFragment(
    graphql`
      fragment Timeline_dashboard on Dashboard {
        key
        providers
      }
    `,
    dashboard,
  );

  const provider = data?.providers?.length
    ? (data.providers[0].toLowerCase() as Provider)
    : "";

  const { resultSet, isLoading, error } = useCubeQuery(
    [
      {
        measures: ["ResourceCount.resourceCount"],
        order: [["ResourceCount.date", "asc"]],
        timeDimensions: [
          {
            dimension: "ResourceCount.date",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            member: "Dashboard.key",
            operator: "equals",
            values: [data.key],
          },
          {
            member: "Account.provider",
            operator: "equals",
            values: [provider],
          },
        ],
      },
      {
        measures: ["ControlSignal.distinctResources"],
        order: [["ControlSignal.date", "asc"]],
        timeDimensions: [
          {
            dimension: "ControlSignal.date",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            member: "Dashboard.key",
            operator: "equals",
            values: [data.key],
          },
          {
            member: "Account.provider",
            operator: "equals",
            values: [provider],
          },
        ],
      },
    ],
    { skip: !data },
  );

  const datasets = useDeepCompareMemo(
    () =>
      resultSet?.series().map((s) => ({
        id: s.key,
        data: s.series.map((r) => ({ x: r.x, y: r.value })),
      })),
    [resultSet],
  );

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <Skeleton
          animation="wave"
          height="300px"
          role="progressbar"
          variant="rectangular"
        />
      </Box>
    );
  }

  if (error) {
    Sentry.captureException(error);
    console.error(error.toString());
  }

  const resourcesData = datasets?.find((item) =>
    item.id.includes("resourceCount"),
  );
  const complianceData = datasets?.find((item) =>
    item.id.includes("distinctResources"),
  );

  if (!resourcesData && !complianceData) {
    return null;
  }

  // convert match count to compliance percentage
  complianceData?.data.forEach((item, index) => {
    const resourceCount = resourcesData?.data[index].y;
    // item.y is the match count, and we're converting it to a percentage
    item.y = resourceCount
      ? (100 * (resourceCount - item.y)) / resourceCount
      : 0;
  });

  const isCostDashboard = data.key.includes("cost");

  return (
    <Box sx={{ height: "350px", position: "relative" }}>
      <Box sx={{ width: "100vw" }}>
        <Typography variant="h2">
          {isCostDashboard ? `Efficiency timeline` : `Compliance timeline`}
        </Typography>
      </Box>
      <Typography variant="body2">
        Shows changes over time in the total count of evaluated resources, as
        well as the percentage of those resources that are compliant with these
        recommendations
      </Typography>
      {resourcesData ? (
        <Box
          data-testid="resources-chart"
          sx={{
            height: "300px",
            position: "absolute",
            top: "50px",
            width: "100%",
            zIndex: 4,
          }}
        >
          <ResponsiveLine
            axisBottom={null}
            axisLeft={{
              tickSize: 0,
              tickValues: 5,
              legend: "# Resources",
              legendOffset: -50,
              legendPosition: "end",
            }}
            colors={[CATEGORICAL_CHART_COLORS[0]]}
            data={[resourcesData]}
            enableGridX={false}
            enablePoints={false}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            theme={getColoredAxis(CATEGORICAL_CHART_COLORS[0])}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
          />
        </Box>
      ) : null}
      {complianceData ? (
        <Box
          data-testid="compliance-chart"
          sx={{
            height: "300px",
            position: "absolute",
            top: "50px",
            width: "100%",
            zIndex: 5,
          }}
        >
          <ResponsiveLine
            axisBottom={{
              tickValues: "every week",
              tickPadding: 5,
              tickRotation: 90,
              format: (value) => format(new Date(value), "MMM dd"),
            }}
            axisLeft={null}
            axisRight={{
              format: (value) => `${value.toFixed(2)} %`,
              tickSize: 0,
              tickValues: 5,
              legend: isCostDashboard ? "% Efficiency" : "% Compliance",
              legendOffset: 50,
              legendPosition: "end",
            }}
            colors={[CATEGORICAL_CHART_COLORS[1]]}
            data={[complianceData]}
            enableGridX={false}
            enableGridY={false}
            enablePoints={false}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            theme={getColoredAxis(CATEGORICAL_CHART_COLORS[1])}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}
