import graphql from "babel-plugin-relay/macro";

export const EditPolicyCollectionMutation = graphql`
  mutation EditPolicyCollectionMutation(
    $input: UpdatePolicyCollectionInput!
    $updateMappingsInput: UpsertPolicyCollectionMappingsInput!
    $removeMappingsInput: RemovePolicyCollectionMappingsInput!
    $connections: [ID!]!
  ) {
    updatePolicyCollection(input: $input) {
      collection {
        id
        uuid
        name
        description
        autoUpdate
      }
    }
    removePolicyCollectionMappings(input: $removeMappingsInput) {
      removed {
        id @deleteRecord
      }
    }
    upsertPolicyCollectionMappings(input: $updateMappingsInput) {
      mappings
        @appendNode(
          connections: $connections
          edgeTypeName: "PolicyCollectionMappingEdge"
        ) {
        policy {
          name
          id
        }
      }
    }
  }
`;
