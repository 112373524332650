/**
 * @generated SignedSource<<8d8813bde9494bb1b623c5cf7db8489c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Runs_bindingRuns$data = {
  readonly runs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly executionErrorCount: number;
        readonly expectedExecutionCount: number;
        readonly lastUpdateTime: string | null | undefined;
        readonly observedExecutionCount: number;
        readonly runID: string;
        readonly startTime: string | null | undefined;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "Runs_bindingRuns";
};
export type Runs_bindingRuns$key = {
  readonly " $data"?: Runs_bindingRuns$data;
  readonly " $fragmentSpreads": FragmentRefs<"Runs_bindingRuns">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Runs_bindingRuns",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BindingRunConnection",
      "kind": "LinkedField",
      "name": "runs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BindingRunEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BindingRun",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "runID",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastUpdateTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "observedExecutionCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expectedExecutionCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "executionErrorCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Binding",
  "abstractKey": null
};

(node as any).hash = "43a3f461c18d4a84fc37530d6c60b130";

export default node;
