import graphql from "babel-plugin-relay/macro";

export const SendTestMessageMutation = graphql`
  mutation SendTestMessageMutation($input: SendTestMessageInput!) {
    sendTestMessage(input: $input) {
      message
      success
    }
  }
`;
