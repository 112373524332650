import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { Compliance } from "./Compliance";

import type { OverallCompliance_dashboard$key } from "./__generated__/OverallCompliance_dashboard.graphql";

interface Props {
  dashboard: OverallCompliance_dashboard$key;
}

export function OverallCompliance({ dashboard }: Props) {
  const data = useFragment(
    graphql`
      fragment OverallCompliance_dashboard on Dashboard {
        key
        metric
        providers
        title
      }
    `,
    dashboard,
  );

  const provider = data?.providers?.length
    ? data.providers[0].toLowerCase()
    : "";

  return (
    <Compliance
      dashboardKey={data.key}
      metric={data.metric}
      provider={provider}
      title={data.title}
    />
  );
}
