import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import graphql from "babel-plugin-relay/macro";
import { Helmet } from "react-helmet-async";
import { useLazyLoadQuery } from "react-relay";

import type { SupportQuery } from "./__generated__/SupportQuery.graphql";

const openSupportWidget = () => {
  window.FreshworksWidget("open");
};

const FRESHWORKS_WIDGET_SRC =
  "https://widget.freshworks.com/widgets/72000002430.js";

export default function Support(): JSX.Element {
  const data = useLazyLoadQuery<SupportQuery>(
    graphql`
      query SupportQuery {
        whoami {
          email
          displayName
        }
      }
    `,
    {},
  );

  const { email, displayName } = data.whoami;

  return (
    <>
      <Helmet>
        <script>
          {`window.fwSettings={'widget_id':72000002430};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
        </script>
        <script
          src={FRESHWORKS_WIDGET_SRC}
          type="text/javascript"
          async
          defer
        ></script>
        <script>{`FreshworksWidget('hide', 'launcher');`}</script>
        <script>
          {`
         FreshworksWidget('identify', 'ticketForm',
           {name: '${displayName}',
            email: '${email}',
            }); 
        `}
        </script>
      </Helmet>
      <Link component="button" onClick={openSupportWidget}>
        <Typography color="textSecondary" variant="body2">
          Support
        </Typography>
      </Link>
    </>
  );
}
