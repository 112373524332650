import graphql from "babel-plugin-relay/macro";

export const AddAccountGroupItemsMutation = graphql`
  mutation AddAccountGroupItemsMutation($input: AccountGroupItemsInput!) {
    addAccountGroupItems(input: $input) {
      group {
        id
        uuid
        name
        description
        regions
        provider
        items {
          uuid
          key
          provider
          name
          regions
        }
      }
    }
  }
`;
