import graphql from "babel-plugin-relay/macro";

export const AddAccountMutation = graphql`
  mutation AddAccountMutation($connections: [ID!]!, $input: AccountInput!) {
    addAccount(input: $input) {
      account
        @appendNode(connections: $connections, edgeTypeName: "AccountEdge") {
        id
        name
        provider
        key
        path
        email
        securityContext
        status
        status_message
      }
    }
  }
`;
