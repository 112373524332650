import graphql from "babel-plugin-relay/macro";

export const AddPolicyCollectionMutation = graphql`
  mutation AddPolicyCollectionMutation(
    $input: AddPolicyCollectionInput!
    $connections: [ID!]!
  ) {
    addPolicyCollection(input: $input) {
      collection
        @appendNode(
          connections: $connections
          edgeTypeName: "PolicyCollectionEdge"
        ) {
        id
        uuid
        name
        description
        provider
        autoUpdate
        items {
          uuid
          name
          version
        }
        policyMappings {
          edges {
            node {
              policy {
                name
              }
            }
          }
        }
      }
    }
  }
`;
