import { useCallback, useState } from "react";

import type { FetchPolicy } from "react-relay";

export interface RefreshQueryOptions {
  fetchKey: number;
  fetchPolicy: FetchPolicy;
}

export function useLazyRefreshQueryOptions(): [
  RefreshQueryOptions,
  () => void,
] {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchKey: 0,
    fetchPolicy: "network-only" as FetchPolicy,
  });
  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      fetchKey: prev.fetchKey + 1,
      fetchPolicy: "network-only" as FetchPolicy,
    }));
  }, []);

  return [refreshedQueryOptions, refresh];
}
