import { forwardRef } from "react";

import { Paper as MuiPaper } from "@mui/material";

import type { PaperProps } from "@mui/material";

export const Paper = forwardRef<HTMLDivElement, PaperProps>(
  ({ ...props }, ref) => {
    return (
      <MuiPaper {...props} elevation={0} ref={ref}>
        {props.children}
      </MuiPaper>
    );
  }
);
