import graphql from "babel-plugin-relay/macro";

export const UpsertUserDataMutation = graphql`
  mutation UpsertUserDataMutation($input: UpsertUserDataInput!) {
    upsertUserData(input: $input) {
      userData {
        name
        type
        value
      }
    }
  }
`;
