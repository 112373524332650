import type React from "react";
import { createContext, useContext } from "react";

import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import type {
  UserContext_whoami$key,
  UserContext_whoami$data,
} from "./__generated__/UserContext_whoami.graphql";

interface Props {
  children: React.ReactNode;
  query: UserContext_whoami$key;
}

export type Permission = UserContext_whoami$data["whoami"]["permissions"][0];

const UserContext = createContext<Permission[]>([]);

export const UserContextProvider = ({
  children,
  query,
}: Props): JSX.Element => {
  const data = useFragment(
    graphql`
      fragment UserContext_whoami on Query {
        whoami {
          permissions {
            object
            action
          }
        }
      }
    `,
    query,
  );

  let permissions: Permission[] = [];
  if (data?.whoami) {
    permissions = data.whoami.permissions.map((permission) => ({
      object: permission.object,
      action: permission.action,
    }));
  }
  return (
    <UserContext.Provider value={permissions}>{children}</UserContext.Provider>
  );
};

export const useUserContext = (): Permission[] => {
  return useContext(UserContext);
};
