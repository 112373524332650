import { useContext } from "react";

import { ProviderContext, useConfig, useUserContext } from "app/contexts";
import { useEnabledDashboards } from "app/hooks";
import { adminPermissions } from "app/utils/consts";
import { isEqual } from "app/utils/permissions";

import type { Permission } from "app/contexts";

interface BaseNavItems {
  label: string;
  id: string;
  isExternalLink?: boolean;
  mainPath?: string;
  permissionRequired?: {
    object: string;
    action: string;
  };
}

interface NavItemsLeafNode extends BaseNavItems {
  target: string;
  children?: never;
}

interface NavItemsBranchNode extends BaseNavItems {
  children: NavItem[];
  target?: never;
}

export type NavItem = NavItemsBranchNode | NavItemsLeafNode;

interface ContainesCurrentRouteProps {
  currentRoute: string;
  navItems: NavItem[];
}

export const containsCurrentRoute = ({
  currentRoute,
  navItems,
}: ContainesCurrentRouteProps): boolean =>
  navItems.some((navItem) =>
    navItem.target
      ? currentRoute.startsWith(navItem.target)
      : containsCurrentRoute({
          currentRoute,
          navItems: navItem.children ?? [],
        }),
  );

function filterItemsByPermissions(
  items: NavItem[],
  permissions: Permission[],
): NavItem[] {
  const isAllowed = (item: NavItem) => {
    const { permissionRequired } = item;
    return permissionRequired
      ? permissions.some((permission) =>
          isEqual(permission, permissionRequired),
        )
      : true;
  };

  return items.filter((item) => {
    item.children = item.children?.filter((child: NavItem) => isAllowed(child));
    return isAllowed(item);
  });
}

export function useNavItems(extraFields: NavItem[]): NavItem[][] {
  const config = useConfig();
  const permissions = useUserContext();
  const provider = useContext(ProviderContext);
  const dashboards = useEnabledDashboards(provider);

  const navItems: NavItem[] = [
    {
      label: "Overview",
      id: "overview",
      children: [
        {
          label: "Cloud",
          id: "cloud",
          target: "/overview",
        },
        ...(config?.activity_views_enabled
          ? [
              {
                label: "Stacklet activity",
                target: "/activity",
                id: "activity",
              },
            ]
          : []),

        ...dashboards,
        {
          label: "Deployed Policies",
          id: "deployed-policies",
          target: "/policies",
        },
      ],
    },
    {
      label: "Cloud Ops",
      id: "cloudops",
      children: [
        {
          label: "Resource Matches",
          target: "/cloudops/resource-matches",
          id: "cloudops-resource-matches",
        },
        ...(config?.resource_explorer_enabled
          ? [
              {
                label: "Resource Explorer",
                target: "/cloudops/resources",
                id: "cloudops-resources",
              },
            ]
          : []),
      ],
    },
    {
      label: "Admin",
      id: "admin",
      permissionRequired: adminPermissions,
      children: [
        {
          label: "Bindings",
          id: "admin-orchestration-bindings",
          target: "/admin/orchestration/bindings",
        },
        {
          label: "Policy Collections",
          id: "admin-controls-policy-collections",
          target: "/admin/controls/policy-collections",
        },
        {
          label: "Account Groups",
          target: "/admin/clouds/account-groups",
          id: "admin-clouds-account-groups",
        },
        {
          label: "Policies",
          id: "admin-controls-policies",
          target: "/admin/controls/policies",
        },
        {
          label: "Accounts",
          target: "/admin/clouds/accounts",
          id: "admin-clouds-accounts",
        },
      ],
    },
    ...(config?.commshub_enabled
      ? [
          {
            label: "Notifications",
            id: "notifications",
            permissionRequired: adminPermissions,
            children: [
              {
                label: "Overview",
                id: "overview",
                target: "/notifications/overview",
              },
              {
                label: "Settings",
                id: "settings",
                target: "/notifications/settings",
              },
            ],
          },
        ]
      : []),
  ];

  const navItemsBottom: NavItem[] = [...extraFields];

  const collapsedNavItems: NavItem[] = [
    {
      label: "Overview",
      target: "/overview",
      id: "overview",
    },
    {
      label: "Cloud Ops",
      target: "/cloudops/resource-matches",
      id: "cloudops",
    },
    {
      label: "Admin",
      target: "/admin/clouds/accounts",
      mainPath: "/admin",
      id: "admin",
      permissionRequired: adminPermissions,
    },
    {
      label: "Stacklet Tools",
      target: "",
      isExternalLink: true,
      id: "stacklet-tools",
    },
  ];

  const collapsedNavItemsBottom: NavItem[] = [
    ...(config?.commshub_enabled
      ? [
          {
            label: "Notifications",
            target: "/notifications",
            id: "notifications",
            permissionRequired: adminPermissions,
          },
        ]
      : []),
  ];

  const items = filterItemsByPermissions(navItems, permissions);
  const collapsedItems = filterItemsByPermissions(
    collapsedNavItems,
    permissions,
  );
  const bottomItems = filterItemsByPermissions(navItemsBottom, permissions);
  const collapsedBottomItems = filterItemsByPermissions(
    collapsedNavItemsBottom,
    permissions,
  );

  return [items, collapsedItems, bottomItems, collapsedBottomItems];
}
