import graphql from "babel-plugin-relay/macro";

export const RemoveReportGroupsMutation = graphql`
  mutation RemoveReportGroupsMutation(
    $names: [String!]!
    $connections: [ID!]!
  ) {
    removeReportGroups(names: $names) @deleteEdge(connections: $connections)
  }
`;
