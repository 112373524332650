import graphql from "babel-plugin-relay/macro";

export const AddEmailProfileMutation = graphql`
  mutation AddEmailProfileMutation(
    $connections: [ID!]!
    $input: EmailConfigurationInput!
  ) {
    addEmailProfile(input: $input) {
      configuration
        @appendNode(
          connections: $connections
          edgeTypeName: "ConfigurationEdge"
        ) {
        id
        scope
        profile
        record {
          ... on EmailConfiguration {
            fromEmail
            smtp {
              server
              port
              ssl
              username
            }
            sesRegion
          }
        }
      }
    }
  }
`;
