import { Suspense } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { DialogFallback } from "../fallbacks";

import type { DialogProps } from "@mui/material/Dialog";

export function SuspenseDialog({
  title,
  maxWidth = "sm",
  onClose,
  open,
  children,
  ...rest
}: DialogProps) {
  return (
    <Suspense fallback={<DialogFallback />}>
      <Dialog
        aria-labelledby="form-dialog"
        maxWidth={maxWidth}
        onClose={onClose}
        open={open}
        {...rest}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Suspense>
  );
}
