import { createContext, useContext, useMemo } from "react";
import type React from "react";

import { AnalyticsBrowser } from "@segment/analytics-next";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import Cookies from "universal-cookie";

import { getAuthCookieOptions } from "app/utils/auth";

import { useConfig } from "./ConfigContext";

import type { AnalyticsContextQuery } from "./__generated__/AnalyticsContextQuery.graphql";

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

export const AnalyticsProvider = ({ children }: Props) => {
  const config = useConfig();

  const data = useLazyLoadQuery<AnalyticsContextQuery>(
    graphql`
      query AnalyticsContextQuery($filterElement: FilterElementInput) {
        userData(filterElement: $filterElement) {
          edges {
            node {
              value
            }
          }
        }
      }
    `,
    {
      filterElement: {
        single: {
          name: "name",
          operator: "equals",
          value: "analytics_enabled",
        },
      },
    },
  );

  const analyticsEnabled = useMemo(
    () =>
      !(data.userData.edges ?? []).find((edge) => edge.node.value === "false"),
    [data.userData],
  );

  useMemo(() => {
    if (config?.segment_write_key) {
      const cookies = new Cookies();
      cookies.set(
        "stacklet-segment-key",
        config.segment_write_key,
        getAuthCookieOptions(),
      );
    }
  }, [config?.segment_write_key]);

  const analytics = useMemo(
    () =>
      AnalyticsBrowser.load(
        { writeKey: config?.segment_write_key || "" },
        {
          integrations: {
            All: analyticsEnabled,
          },
        },
      ),
    [analyticsEnabled, config?.segment_write_key],
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);

  if (!result) {
    throw new Error("AnalyticsContext used outside of its Provider!");
  }

  return result;
};
