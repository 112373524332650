import graphql from "babel-plugin-relay/macro";

export const UpdateAccountMutation = graphql`
  mutation UpdateAccountMutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
        name
        provider
        key
        path
        email
        securityContext
        status
        status_message
        credentialInfo {
          ... on AzureCredentialInfo {
            tenantID
            clientID
          }
          ... on TencentCloudCredentialInfo {
            keyID
          }
        }
      }
    }
  }
`;
