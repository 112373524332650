import graphql from "babel-plugin-relay/macro";

export const AddPolicyCollectionItemsMutation = graphql`
  mutation AddPolicyCollectionItemsMutation(
    $input: PolicyCollectionItemsInput!
  ) {
    addPolicyCollectionItems(input: $input) {
      collection {
        items {
          __typename
        }
      }
    }
  }
`;
