import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import copyIcon from "../../assets/copy.svg";
import { Tooltip } from "../Tooltip";

import type { SxProps } from "@mui/system";
import type { MuiEvent } from "@mui/x-data-grid-pro";

interface Props {
  value: string;
  children?: React.ReactNode;
  sx?: SxProps;
}

export function WithCopyButton({ value, children, sx }: Props) {
  const [copyValueStatus, copyValue] = useCopyToClipboard(value);

  let status = "Copy value";
  if (copyValueStatus === "copied") {
    status = "Copied";
  } else if (copyValueStatus === "failed") {
    status = "Copy failed!";
  }

  return (
    <Stack alignItems="center" direction="row" gap={1} sx={sx}>
      {children || value}
      <Tooltip title={status}>
        <Box
          alt="copy button"
          component="img"
          height="16"
          onClick={(event: MuiEvent<React.MouseEvent>) => {
            event.preventDefault();
            event.stopPropagation();
            copyValue();
          }}
          src={copyIcon}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Tooltip>
    </Stack>
  );
}
