import { useContext } from "react";

import BreadCrumbsHeader from "app/components/BreadcrumbsHeader";
import { ProviderContext, useConfig } from "app/contexts";
import { useEnabledDashboards } from "app/hooks";

export default function OverviewBreadcrumbs() {
  const provider = useContext(ProviderContext);
  const dashboards = useEnabledDashboards(provider);
  const config = useConfig();

  const breadcrumbs = [
    { label: "Overview" },
    {
      tabs: [
        { label: "Cloud", target: `/dashboards/cloud` },
        ...(config?.activity_views_enabled
          ? [
              {
                label: "Stacklet activity",
                target: "/activity",
              },
            ]
          : []),

        ...dashboards,
        { label: "Deployed Policies", target: `/policies` },
      ],
    },
  ];

  return <BreadCrumbsHeader breadcrumbs={breadcrumbs} />;
}
