import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { AnnualCost } from "./AnnualCost";

import type { OverallAnnualCost_dashboard$key } from "./__generated__/OverallAnnualCost_dashboard.graphql";

interface Props {
  dashboard: OverallAnnualCost_dashboard$key;
}

export function OverallAnnualCost({ dashboard }: Props) {
  const data = useFragment(
    graphql`
      fragment OverallAnnualCost_dashboard on Dashboard {
        __typename
        key
        providers
      }
    `,
    dashboard,
  );

  const provider = data?.providers?.length
    ? data.providers[0].toLowerCase()
    : "";

  return <AnnualCost dashboardKey={data.key} provider={provider} />;
}
