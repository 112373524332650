import graphql from "babel-plugin-relay/macro";

export const DeployBindingMutation = graphql`
  mutation DeployBindingMutation($uuid: String!) {
    deployBinding(uuid: $uuid) {
      binding {
        uuid
        name
      }
    }
  }
`;
