import graphql from "babel-plugin-relay/macro";

export const AddOwnerProfileMutation = graphql`
  mutation AddOwnerProfileMutation(
    $connections: [ID!]!
    $resourceOwnerProfileInput: ResourceOwnerConfigurationInput!
    $accountOwnerProfileInput: AccountOwnersConfigurationInput!
  ) {
    addResourceOwnerProfile(input: $resourceOwnerProfileInput) {
      configuration
        @appendNode(
          connections: $connections
          edgeTypeName: "ConfigurationEdge"
        ) {
        profile
        record {
          ... on ResourceOwnerConfiguration {
            tags
            default
          }
        }
      }
    }
    addAccountOwnersProfile(input: $accountOwnerProfileInput) {
      configuration {
        profile
        record {
          ... on AccountOwnersConfiguration {
            tags
            default {
              owners
            }
          }
        }
      }
    }
  }
`;
