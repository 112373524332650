import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

interface Props {
  openDialog(arg: boolean): void;
  isDialogOpen: boolean;
  onViewChanged(arg: string): void;
  children: React.ReactNode;
  selectedValue: string;
  radioOptions: { title: string; value: string }[];
  type: string;
}

export function DialogWithRadioOptions({
  children,
  openDialog,
  isDialogOpen,
  radioOptions,
  onViewChanged,
  selectedValue,
  type,
}: Props): JSX.Element {
  const handleChangeView = (event: React.ChangeEvent<HTMLInputElement>) => {
    onViewChanged(event.target.value);
  };

  return (
    <>
      <Dialog
        aria-labelledby={`form-dialog-${type}`}
        maxWidth="md"
        onClose={() => {
          openDialog(false);
        }}
        open={isDialogOpen}
        fullWidth
      >
        <DialogTitle
          className="dialogTitle"
          data-testid={`form-dialog-${type}-${selectedValue}`}
          id={`form-dialog-${type}`}
        >
          {
            radioOptions.filter(
              (option: { title: string; value: string }) =>
                option.value === selectedValue,
            )[0].title
          }
        </DialogTitle>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="view-dialog"
            name="viewDialog"
            onChange={handleChangeView}
            sx={{ pt: 2, px: 3, pb: 0 }}
            value={selectedValue}
            row
          >
            {radioOptions.map((option: { title: string; value: string }) => (
              <FormControlLabel
                control={<Radio />}
                key={option.value}
                label={option.title}
                value={option.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <DialogContent sx={{ pt: 3 }}>{children}</DialogContent>
      </Dialog>
    </>
  );
}
