import graphql from "babel-plugin-relay/macro";

export const UpdateGroupRolesMutation = graphql`
  mutation UpdateGroupRolesMutation(
    $connections: [ID!]!
    $groupRoleInput: SetGroupRolesInput!
    $defaultRoleInput: UpdateDefaultRoleInput!
  ) {
    setGroupRoles(input: $groupRoleInput) {
      added
        @appendNode(connections: $connections, edgeTypeName: "GroupRoleEdge") {
        id
        role
        group
      }
      removed @deleteEdge(connections: $connections) {
        id @deleteRecord
      }
    }
    updateDefaultRole(input: $defaultRoleInput)
  }
`;
