import graphql from "babel-plugin-relay/macro";

export const TriggerAccountDiscoveryMutation = graphql`
  mutation TriggerAccountDiscoveryMutation(
    $input: TriggerAccountDiscoveryInput!
  ) {
    triggerAccountDiscovery(input: $input) {
      accountDiscoveries {
        scans {
          edges {
            node {
              uuid
              completed
              error
            }
          }
        }
      }
    }
  }
`;
