import { forwardRef } from "react";

import { Card as MuiCard } from "@mui/material";

import type { CardProps } from "@mui/material";

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ ...props }, ref) => {
    return (
      <MuiCard {...props} elevation={0} ref={ref}>
        {props.children}
      </MuiCard>
    );
  }
);
