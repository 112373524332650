import graphql from "babel-plugin-relay/macro";

export const AddAccountGroupMutation = graphql`
  mutation AddAccountGroupMutation(
    $input: AddAccountGroupInput!
    $connections: [ID!]!
  ) {
    addAccountGroup(input: $input) {
      group
        @appendNode(
          connections: $connections
          edgeTypeName: "AccountGroupEdge"
        ) {
        id
        uuid
        name
        provider
        regions
        dynamicFilter
      }
    }
  }
`;
